import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as Prism from 'prismjs';

// componente che crea un box informativo (più piccolo di info-box)
@Component({
  selector: 'app-small-box',
  templateUrl: './small-box.component.html',
  styleUrls: ['./small-box.component.scss'],
})
export class SmallBoxComponent implements AfterViewInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() body: string;
  @Input() icon: string;
  @Input() isPercentage = false;
  @Input() link: string;
  @Input() linkText: string;
  @Input() class: string;
  /**
   * @method ngAfterViewInit
   */
  ngAfterViewInit() {
    Prism.highlightAll();
  }

  constructor(public router: Router) {}

  goTo() {
    this.router.navigate([this.link]);
  }
}
