export class AppConstants {
  public static FORNITORE_EDIT_URL = '/a-fornitore/modifica';
  public static CLIENTE_EDIT_URL = '/a-cliente/modifica';
  public static NOTIFICA_EDIT_URL = '/a-notifica/modifica';
  public static STRUMENTO_EDIT_URL = '/a-strumento/modifica';
  public static SCADENZARIO_EDIT_URL = '/a-scadenzario/modifica';
  public static CLIENTE_DETAIL_URL = 'a-cliente/dettaglio';
  public static COMMESSA_EDIT_URL = '/a-commessa/modifica';
  public static COMMESSA_DIPENDENTE_EDIT_URL =
    '/a-commessa/modificaCommessaDipendente';
  public static STRUMENTO_DIPENDENTE_EDIT_URL =
    '/a-strumento/modifica-strumento-dipendente';
  public static COMPETENZA_EDIT_URL = '/a-competenza/modifica';
  public static COMPETENZA_DIPENDENTE_EDIT_URL =
    '/a-competenzeDipendente/modifica';
  public static CONTRATTO_CLIENTE_EDIT_URL =
    '/a-contratto/modifica-contratto-cliente';
  public static CONTRATTO_FORNITORE_EDIT_URL =
    '/a-contratto/modifica-contratto-fornitore';
  public static DIPENDENTE_EDIT_URL = '/a-dipendente/modifica';
  public static F24_EDIT_URL = '/a-f24/modifica';
  public static ENTRATE_EDIT_URL = '/a-entrate-uscite/modifica-entrata';
  public static USCITE_EDIT_URL = '/a-entrate-uscite/modifica-uscita';
  public static FATTURA_EMESSA_EDIT_URL = '/a-fattura/modifica-emessa';
  public static FATTURA_RICEVUTA_EDIT_URL = '/a-fattura/modifica-ricevuta';
  public static RUOLO_EDIT_URL = '/a-ruolo/modifica';
  public static SALDO_INIZIALE_EDIT_URL = '/a-saldo-iniziale/modifica';
  public static RUOLO_PROFESSIONALE_EDIT_URL =
    '/a-ruolo-professionale/modifica';
  public static SOCIETA_EDIT_URL = '/a-societa/modifica';
  public static STIPENDI_DIPENDENTI_EDIT_URL =
    '/a-stipendi-dipendenti/modifica';
  public static CU_EDIT_URL = '/a-cu/modifica';
  public static TIPO_FATTURA_EDIT_URL = '/a-fattura/modifica-tipo-fattura';
  public static UTENTE_EDIT_URL = '/a-utente/modifica';
  public static TIPO_PAGAMENTO_EDIT_URL = '/a-fattura/modifica-tipo-pagamento';
  public static TIMESHEET_EDIT_URL = '/timesheet/modifica';
  public static TIMESHEET_ADMIN_EDIT_URL = '/a-timesheet/modifica';

  public static DELETED_CODE = 'deleted';
  public static DENOMINAZIONE_CODE = 'denominazione';
  public static IDENTIFICATIVO_CODE = 'identificativo';
  public static ID_TIPO_RUOLO_CODE = 'idTipoRuolo';
  public static PARTITA_IVA_CODE = 'partitaIva';
  public static CODICE_FISCALE_CODE = 'codiceFiscale';
  public static PARTITA_IVA_CODICE_FISCALE_CODE = 'pivaCodfis';
  public static CODICE_UNIVOCO_CODE = 'codiceUnivoco';
  public static CODICE_REA_CODE = 'codiceRea';
  public static PEC_CODE = 'pec';
  public static CAP_CODE = 'cap';
  public static LIVELLO_CODE = 'livello';
  public static INDIRIZZO_CODE = 'indirizzo';
  public static NOME_CODE = 'nome';
  public static CLIENTE_DENOMINAZIONE_CODE = 'cliente.denominazione';
  public static COMPETENZA_CODE = 'competenza';
  public static COMPETENZA_COMPETENZA_CODE = 'competenza.competenza';
  public static DIPENDENTE_NOME_COGNOME_CODE =
    'dipendente.nome;dipendente.cognome';
  public static DIPENDENTE_MATRICOLA_CODE = 'dipendente.matricola';
  public static DIPENDENTE_CODE = 'dipendente';
  public static CODICE_CODE = 'codice';
  public static DATA_CONTRATTO_CODE = 'dataContratto';
  public static DATA_INIZIO_ATTIVITA_CODE = 'dataInizioAttivita';
  public static DATA_FINE_ATTIVITA_CODE = 'dataFineAttivita';
  public static CONTRATTO_DATA_FINE_CODE = 'contratto.dataFineAttivita';
  public static COMMESSA_DIPENDENTE_DATA_FINE_CODE =
    'commessa.contratto.dataFineAttivita';
  public static SOCIETA_DENOMINAZIONE_CODE = 'societa.denominazione';
  public static ID_SOCIETA_CODE = 'idSocieta';
  public static ID_CLIENTE_CODE = 'idCliente';
  public static FATTURE_SCADUTE_DA_PAGARE_CODE = 'fattureScaduteDaPagare';
  public static ID_FORNITORE_CODE = 'idFornitore';
  public static MATRICOLA_CODE = 'matricola';
  public static HANGOUT_CODE = 'hangout';
  public static SKYPE_CODE = 'skype';
  public static RUOLO_PROFESSIONALE_CODE = 'ruoloProfessionale';
  public static COGNOME_CODE = 'cognome';
  public static COMPETENZE_CODE = 'competenze';
  public static IMPORTO_CODE = 'importo';
  public static ID_TIPO_PAGAMENTO_CODE = 'tipoPagamento.id';
  public static NOME_TIPO_PAGAMENTO_CODE = 'tipoPagamento.nome';
  public static PERIODO_RIFERIMENTO_CODE = 'periodoRiferimento';
  public static DATA_PAGAMENTO_CODE = 'dataPagamento';
  public static DATA_RIFERIMENTO_CODE = 'dataRiferimento';
  public static ANNO_RIFERIMENTO_CODE = 'annoRiferimento';
  public static DATA_SCADENZA_CODE = 'dataScadenza';
  public static DATA_FATTURA_CODE = 'dataFattura';
  public static MESSAGGIO_CODE = 'messaggio';
  public static UTENTE_LETTURA_CODE = 'utenteLettura';
  public static LETTO_CODE = 'letto';
  public static DATA_NOTIFICA_CODE = 'dataNotifica';
  public static DATA_ANNO_FATTURA_CODE = 'dataAnnoFattura';
  public static DATA_ANNO_PAGAMENTO_CODE = 'dataAnnoPagamento';
  public static DATA_ANNO_SCADENZA_CODE = 'dataAnnoScadenza';
  public static DATA_AVVISO_CODE = 'dataAvviso';

  public static IMPONIBILE_CODE = 'imponibile';
  public static VALORE_IMPOSTA_CODE = 'valoreImposta';
  public static TOTALE_FATTURA_CODE = 'totaleFattura';
  public static TOTALE_PAGATO_CODE = 'totalePagato';
  public static FORNITORE_DENOMINAZIONE_CODE = 'fornitore.denominazione';
  public static LETTURA_CODE = 'lettura';
  public static SCRITTURA_CODE = 'scrittura';
  public static ID_DIPENDENTE_CODE = 'idDipendente';
  public static ID_COMMESSA_CODE = 'idCommessa';
  public static ID_COMPETENZA_CODE = 'idCompetenza';
  public static USERNAME_CODE = 'username';
  public static RUOLO_NOME_CODE = 'ruolo.nome';
  public static ID_RUOLO_CODE = 'idRuolo';
  public static DATA_CODE = 'data';
  public static COMMESSA_NOME_CODE = 'commessa.nome';
  public static COMMESSA_TARIFFA_CODE = 'commessa.tariffa';
  public static NOTE_CODE = 'note';
  public static COMMESSA_CODE = 'commessa';
  public static DIPENDENTE_SOCIETA_DENOMINAZIONE_CODE =
    'dipendente.societa.denominazione';
  public static CONTRATTO_CODICE_CODE = 'contratto.codice';
  public static DESCRIZIONE_CODE = 'descrizione';
  public static STRUMENTO_NOME_CODE = 'strumento.nome';
  public static STRUMENTO_CODICE_IDENTIFICATIVO_CODE =
    'strumento.codiceIdentificativo';
  public static SHOW_ATTIVE_CODE = 'attiva';
  public static UTENTE_ASSEGNAZIONE_CODE = 'utenteAssegnazione';
  public static UTENTE_MODIFICA_CODE = 'utenteModifica';
  public static TITOLO_CODE = 'titolo';
  public static DURATA_STIMATA_CODE = 'durataStimata';
  public static DURATA_EFFETTIVA_CODE = 'durataEffettiva';
  public static DATA_INIZIO_CODE = 'dataInizio';
  public static DATA_FINE_CODE = 'dataFine';
  public static STATO_CODE = 'stato';

  public static TARIFFA_CODE = 'tariffa';
  public static NOT_EDITABLE_CODE = 'notEditable';
  public static ID_CONTRATTO_CODE = 'idContratto';
  public static EMAILS_CODE = 'emails';
  public static EMAIL_CODE = 'email';
  public static DATA_OPERAZIONE_CODE = 'dataOperazione';
  public static TIPO_CLIENTE_CODE = 'tipoCliente';
  public static TELEFONO_CODE = 'telefono';
  public static CELLULARE_CODE = 'cellulare';
  public static SITO_WEB_CODE = 'sitoWeb';
  public static TIPO_RUOLO_NOME_CODE = 'tipoRuolo.nome';
  public static SOCIETA_CODE = 'societa';
  public static CLIENTE_CODE = 'cliente';
  public static TIPO_FATTURA_CODE = 'tipoFattura';
  public static NUMERO_FATTURA_CODE = 'numeroFattura';
  public static IMPOSTA_CODE = 'imposta';
  public static CAUSALE_CODE = 'causale';
  public static ID_FATTURE_CODE = 'idFatture';
  public static IS_ACCONTO_CODE = 'isAcconto';
  public static TIPO_PAGAMENTO_CODE = 'tipoPagamento';
  public static FORNITORE_CODE = 'fornitore';
  public static COOORDINATE_IBAN_CODE = 'coordinateIban';
  public static IBAN_PRINCIPALE_CODE = 'ibanPrincipale';
  public static IBAN_SECONDARIO_CODE = 'ibanSecondario';
  public static BANCA_PRINCIPALE_CODE = 'bancaPrincipale';
  public static CARTA_IDENTITA_CODE = 'cartaIdentita';
  public static DATA_NASCITA_CODE = 'dataNascita';
  public static LUOGO_NASCITA_CODE = 'luogoNascita';
  public static INDIRIZZO_RESIDENZA_CODE = 'indirizzoResidenza';
  public static INDIRIZZO_DOMICILIO_CODE = 'indirizzoDomicilio';
  public static RUOLO_CODE = 'ruolo';
  public static PAGATA_IN_ACCONTI_CODE = 'pagataInAcconti';
  public static IMPONIBILE_MAGGIORE_CODE = 'imponibileMaggiore';
  public static ORE_CODE = 'ore';
  public static CODICE_IDENTIFICATIVO_CODE = 'codiceIdentificativo';
  public static ID_STRUMENTO_CODE = 'idStrumento';
  public static ID_UTENTE_CREAZIONE_CODE = 'idUtenteCreazione';
  public static ID_UTENTE_ASSEGNAZIONE_CODE = 'idUtenteAssegnazione';
  public static ID_UTENTE_NOTIFICA_CODE = 'idUtenteNotifica';
  public static ID_UTENTE_LETTURA_CODE = 'idUtenteLettura';
  public static UTENTE_NOTIFICA_NOME_COGNOME_CODE =
    'utenteNotifica.nome;utenteNotifica.cognome';
  public static UTENTE_LETTURA_NOME_COGNOME_CODE =
    'utenteLettura.nome;utenteLettura.cognome';
  // tslint:disable-next-line: max-line-length
  public static UTENTE_ASSEGNAZIONE_NOME_COGNOME_CODE =
    'utenteAssegnazione.nome;utenteAssegnazione.cognome';
  public static UTENTE_CREAZIONE_NOME_COGNOME_CODE =
    'utenteCreazione.nome;utenteCreazione.cognome';
  public static VISUALIZZA_SCADENZE_COMPLETATE_CODE = 'letto';

  public static LABEL_SCADENZE_COMPLETATE = 'key.scadenze_completate';
  public static LABEL_IVA = 'key.iva';
  public static LABEL_SHOW_CANCELLATI = 'key.visualizza_cancellati';
  public static LABEL_SHOW_ATTIVE = 'key.visualizza_commesse_attive';
  public static LABEL_EMAIL = 'key.email';
  public static LABEL_TIPO_RUOLO = 'key.tipo_ruolo';
  public static LABEL_EMAILS = 'key.emails';
  public static LABEL_PEC = 'key.pec';
  public static LABEL_CAP = 'key.cap';
  public static LABEL_CODICE_FISCALE = 'key.header_codice_fiscale';
  public static LABEL_PARTITA_IVA = 'key.header_partita_iva';
  public static LABEL_DENOMINAZIONE = 'key.header_denominazione';
  public static LABEL_IDENTIFICATIVO = 'key.identificativo';
  public static LABEL_PARTITA_IVA_CODICE_FISCALE =
    'key.partita_iva_codice_fiscale';
  public static LABEL_CODICE_FISCALE_PARTITA_IVA = 'key.header_piva_codfis';
  public static LABEL_CODICE_UNIVOCO = 'key.header_codice_univoco';
  public static LABEL_CODICE_REA = 'key.header_codice_rea';
  public static LABEL_NOME = 'key.nome';
  public static LABEL_CLIENTE_DENOMINAZIONE = 'key.denominazione_cliente';
  public static LABEL_COMPETENZA = 'key.header_competenza';
  public static LABEL_LIVELLO = 'key.livello';
  public static LABEL_DIPENDENTE = 'key.header_dipendente';
  public static LABEL_CODICE = 'key.header_codice';
  public static LABEL_DATA_CONTRATTO = 'key.header_data_contratto';
  public static LABEL_DATA_INIZIO_ATTIVITA = 'key.header_data_inizio_attivita';
  public static LABEL_DATA_FINE_ATTIVITA = 'key.header_dataFine_attivita';
  public static LABEL_SOCIETA = 'key.header_societa';
  public static LABEL_CLIENTE = 'key.cliente';
  public static LABEL_FATTURE_SCADUTE_DA_PAGARE =
    'key.fatture_scadute_da_pagare';
  public static LABEL_UTENTE = 'key.utente';
  public static LABEL_MATRICOLA = 'key.header_matricola';
  public static LABEL_RUOLO_PROFESSIONALE = 'key.ruolo_professionale';
  public static LABEL_COMPETENZE = 'key.competenze';
  public static LABEL_HANGOUT = 'key.hangout';
  public static LABEL_SKYPE = 'key.skype';
  public static LABEL_COGNOME = 'key.header_cognome';
  public static LABEL_IMPORTO = 'key.header_importo';
  public static LABEL_PERIODO_RIFERIMENTO = 'key.header_periodo_riferimento';
  public static LABEL_DATA_PAGAMENTO = 'key.header_data_pagamento';
  public static LABEL_DATA_SCADENZA = 'key.header_data_scadenza';
  public static LABEL_DATA_FATTURA = 'key.header_data_fattura';
  public static LABEL_MESSAGGIO = 'key.messaggio';
  public static LABEL_UTENTE_LETTURA = 'key.utente_lettura';
  public static LABEL_LETTO = 'key.letto';
  public static LABEL_DATA_NOTIFICA = 'key.data_notifica';
  public static LABEL_DATA_ANNO_FATTURA = 'key.anno_fattura';
  public static LABEL_DATA_ANNO_PAGAMENTO = 'key.anno_pagamento';
  public static LABEL_DATA_ANNO_SCADENZA = 'key.anno_scadenza';
  public static LABEL_DATA_AVVISO = 'key.data_avviso';
  public static LABEL_IMPONIBILE = 'key.header_imponibile';
  public static LABEL_VALORE_IMPOSTA = 'key.header_valore_imposta';
  public static LABEL_TOTALE_FATTURA = 'key.header_totale';
  public static LABEL_TOTALE_PAGATO = 'key.header_pagato';
  public static LABEL_FORNITORE = 'key.fornitore';
  public static LABEL_PAGATA_IN_ACCONTI = 'key.pagata_in_acconti';
  public static LABEL_IMPONIBILE_MAGGIORE = 'key.imponibile_maggiore';
  public static LABEL_FORNITORE_DENOMINAZIONE = 'key.denominazione_fornitore';
  public static LABEL_LETTURA = 'key.header_lettura';
  public static LABEL_SCRITTURA = 'key.header_scrittura';
  public static LABEL_INDIRIZZO = 'key.header_indirizzo';
  public static LABEL_DATA_RIFERIMENTO = 'key.data_riferimento';
  public static LABEL_ANNO_RIFERIMENTO = 'key.anno_riferimento';
  public static LABEL_RUOLO = 'key.header_ruolo';
  public static LABEL_DATA = 'key.header_data';
  public static LABEL_ORE = 'key.ore';
  public static LABEL_COMMESSA = 'key.header_commessa';
  public static LABEL_NOTE = 'key.header_note';
  public static LABEL_TARIFFA = 'key.tariffa';
  public static LABEL_CONTRATTO = 'key.contratto';
  public static LABEL_DESCRIZIONE = 'key.descrizione';
  public static LABEL_TIPO_CLIENTE = 'key.tipo_cliente';
  public static LABEL_TELEFONO = 'key.telefono';
  public static LABEL_CELLULARE = 'key.cellulare';
  public static LABEL_SITO_WEB = 'key.sitoWeb';
  public static LABEL_TIPO_FATTURA = 'key.tipo_fattura';
  public static LABEL_NUMERO_FATTURA = 'key.n_fattura';
  public static LABEL_IMPOSTA = 'key.imposta';
  public static LABEL_CAUSALE = 'key.causale';
  public static LABEL_FATTURE_EMESSE = 'key.fatture_emesse';
  public static LABEL_FATTURE_RICEVUTE = 'key.fatture_ricevute';
  public static LABEL_ACCONTO = 'key.acconto';
  public static LABEL_TIPO_PAGAMENTO = 'key.tipo_pagamento';
  public static LABEL_COOORDINATE_IBAN = 'key.coordinate_iban';
  public static LABEL_IBAN_PRINCIPALE = 'key.iban_principale';
  public static LABEL_IBAN_SECONDARIO = 'key.iban_secondario';
  public static LABEL_BANCA_PRINCIPALE = 'key.banca_principale';
  public static LABEL_CARTA_IDENTITA = 'key.carta_identita';
  public static LABEL_DATA_NASCITA = 'key.data_nascita';
  public static LABEL_LUOGO_NASCITA = 'key.luogo_nascita';
  public static LABEL_INDIRIZZO_RESIDENZA = 'key.indirizzo_residenza';
  public static LABEL_INDIRIZZO_DOMICILIO = 'key.indirizzo_domicilio';
  public static LABEL_NON_EDITABILE = 'key.non_editabile';
  public static LABEL_UTENTE_ASSEGNAZIONE = 'key.utente_assegnazione';
  public static LABEL_UTENTE_MODIFICA = 'key.utente_modifica';
  public static LABEL_TITOLO = 'key.titolo';
  public static LABEL_DURATA_STIMATA = 'key.durata_stimata';
  public static LABEL_DURATA_EFFETTIVA = 'key.durata_effettiva';
  public static LABEL_DATA_INIZIO = 'key.data_inizio';
  public static LABEL_DATA_FINE = 'key.data_fine';
  public static LABEL_STATO = 'key.stato';
  public static LABEL_CODICE_IDENTIFICATIVO =
    'key.header_codice_identificativo';
  public static LABEL_STRUMENTO = 'key.header_strumento';
  public static LABEL_UTENTE_CREAZIONE = 'key.utente_creazione';
  public static LABEL_UTENTE_NOTIFICA = 'key.utente_notifica';
  public static LABEL_NOTIFICHE_LETTO = 'key.notifiche_letto';
}
