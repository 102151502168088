export enum PxltkComboName {
  UTENTE = 'UTENTE',
  SOCIETA = 'SOCIETA',
  CONTRATTO = 'CONTRATTO',
  CLIENTE = 'CLIENTE',
  FORNITORE = 'FORNITORE',
  COMMESSE_DIPENDENTE = 'COMMESSE_DIPENDENTE',
  TIPO_FATTURA = 'TIPO_FATTURA',
  TIPO_PAGAMENTO = 'TIPO_PAGAMENTO',
  FATTURE_RICEVUTE = 'FATTURE_RICEVUTE',
  FATTURE_EMESSE = 'FATTURE_EMESSE',
  SI_NO = 'SI_NO',
  SI_NO_ALL = 'SI_NO_ALL',
  DIPENDENTE = 'DIPENDENTE',
  DIPENDENTE_CLONE = 'DIPENDENTE_CLONE',
  COMMESSA = 'COMMESSA',
  RUOLO = 'RUOLO',
  RUOLO_PROFESSIONALE = 'RUOLO_PROFESSIONALE',
  COMPETENZA = 'COMPETENZA',
  TIPO_CLIENTE = 'TIPO_CLIENTE',
  TIPOLOGIA_FATTURA = 'TIPOLOGIA_FATTURA',
  TIPO_RUOLO = 'TIPO_RUOLO',
  STRUMENTO = 'STRUMENTO',
}
