import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { PxltkFormType } from 'src/app/model/enum/pxltk-form-type';
import { PxltkFormModel } from 'src/app/model/pxltk-form-model';
import { EncryptionService } from 'src/app/shared/shared-services/encryption.service';
import { SharedCallService } from 'src/app/shared/shared-services/shared-call.service';
import { SharedService } from '../../shared/shared-services/shared.service';
import { PxltkFormSearchBaseComponent } from '../pxltk-form-search-base-component';
import { StarRatingColor } from '../star-rating/star-rating.component';

// component per la creazione di form
@Component({
  selector: 'app-pxltk-form',
  templateUrl: './pxltk-form.component.html',
  styleUrls: ['./pxltk-form.component.scss'],
})
export class PxltkFormComponent
  extends PxltkFormSearchBaseComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  _formItems: PxltkFormModel[];
  submit = false;
  _title = '';
  starCount = 5;
  rating = 0;
  starColor = StarRatingColor.accent;
  starColorP = StarRatingColor.primary;
  starColorW = StarRatingColor.warn;
  // INPUT
  @Input() showReset: boolean;

  @Input()
  set title(title: string) {
    this._title = title;
  }

  get title(): string {
    return this._title;
  }

  get formItems(): PxltkFormModel[] {
    return this._formItems;
  }

  /**
   * si prende da input una lista di pxltk-form-model e usa il metodo buildForm per la costruzione del form.
   * all'interno di ogni pxltk-form-model è contenuto il tipo di campo, la lista di tutti i tipi è scritto nella enum pxltk-form-type.ts
   */

  @Input()
  set formItems(formItems: PxltkFormModel[]) {
    if (formItems) {
      this.form = undefined;
      this._formItems = formItems;
      this.buildForm();
    }
  }
  @Input() showBack = true;

  // OUTPUT
  @Output() submitEvent = new EventEmitter();
  @Output() resetEvent = new EventEmitter();
  @Output() comboChangeEmitter = new EventEmitter();

  form: FormGroup;

  onRatingChanged(rating) {
    console.log(rating);
    this.rating = rating;
  }

  constructor(
    public sharedService: SharedService,
    public ngxSmartModalService: NgxSmartModalService,
    private spinner: NgxSpinnerService,
    public sharedCallService: SharedCallService,
    public encryptionService: EncryptionService
  ) {
    super(sharedCallService, encryptionService);
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  /**
   * metodo che crea il form sulla base della lista formItem.
   * In base al tipo di ogni campo, il file .html deciderà come disporli sulla pagina
   */

  private buildForm(): void {
    const group = {};
    this._formItems.forEach((field) => {
      group[field.name] = field.required
        ? new FormControl(field.defaultValue, Validators.required)
        : new FormControl(field.defaultValue);

      if (
        field.type === PxltkFormType.COMBO ||
        field.type === PxltkFormType.MULTICOMBO
      ) {
        // BUILD THIS COMBO
        this.buildCombo(field.comboName);
      }
      if (field.type === PxltkFormType.STAR_RATING) {
        this.rating = field.defaultValue;
      }
    });
    this.form = new FormGroup(group);
  }

  ngOnInit(): void {
    // this.combo = [[]];
  }

  resetButton() {
    this.resetEvent.emit(true);
  }

  onSubmit() {
    this.submit = true;
    this.spinner.show();
    if (this.form.invalid) {
      this.spinner.hide();
    } else {
      if (this.rating !== 0) {
        this.form.controls.livello.setValue(this.rating);
      }

      this.submitEvent.emit(this.form.controls);
    }
  }

  comboSelectChange(event: any) {
    const obj = {
      event,
      form: this.form.controls,
    };
    this.comboChangeEmitter.emit(obj);
  }
}
