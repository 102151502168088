import { Utente } from '../dto/utente';

export class GenericCommand {
  public idUtenteOperazione: number;
  public nomeCompletoUtenteOperazione: string;
  public idSocieta: number;
  public id: number;
  userId: number;
  userRoleId: number;
  constructor() {
    const utente: Utente = JSON.parse(sessionStorage.getItem('currentUser'));
    if (!!utente) {
      this.idUtenteOperazione = utente.id;
    }
  }
}
