import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from './auth/role-guard.service';
// tslint:disable-next-line: max-line-length
import { DashboardAdministratorComponent } from './pages/administrator-page/dashboard-administrator/dashboard-administrator.component';
import { DashboardDipendenteComponent } from './pages/dipendente-page/dashboard-dipendente/dashboard-dipendente.component';
import { AppConstants } from './shared/app-constants';
import { Constants } from './shared/constants';

const routes: Routes = [
  {
    path: '',
    data: {
      title: '',
    },
    children: [
      // AMMINISTRAZIONE
      {
        path: '',
        component: DashboardAdministratorComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          exprectedPermission: '1,3',
          path: '',
          showBack: false,
        },
      },
      {
        path: 'dashboard-administrator',
        component: DashboardAdministratorComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: 'menu.dashboard',
          exprectedPermission: '1,3',
          path: 'dashboard-administrator',
          showBack: false,
        },
      },
      {
        path: 'a-cliente',
        data: {
          title: 'cliente',
          path: 'a-cliente',
          showBack: false,
        },
        children: [
          {
            path: 'dettaglio',
            loadChildren:
              './pages/administrator-page/cliente/dettaglio/dettaglio-cliente.module#DettaglioClienteModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              title: 'cliente.list.dettaglio',
              exprectedPermission: '1,3',
              path: 'a-cliente/dettaglio',
              showBack: true,
            },
          },
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/cliente/list/list-cliente.module#ListClienteModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_CLIENTE,
              deleteUrl: Constants.ELIMINA_CLIENTE,
              editUrl: AppConstants.CLIENTE_EDIT_URL,
              detailUrl: AppConstants.CLIENTE_DETAIL_URL,
              searchUrl: Constants.SEARCH_CLIENTE,
              listName: 'clienti',
              title: 'cliente.list.titolo',
              excelUrl: Constants.EXCEL_CLIENTE,
              excelTitle: 'Excel Clienti',
              showDetailButton: true,
              path: 'a-cliente/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/cliente/crea/crea-cliente.module#CreaClienteModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'cliente.creaModifica.titoloCrea',
              urlToCall: Constants.CREA_CLIENTE,
              path: 'a-cliente/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/cliente/crea/crea-cliente.module#CreaClienteModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'cliente.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_CLIENTE,
              getUrl: Constants.GET_CLIENTE,
              path: 'a-cliente/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-commessa',
        data: {
          title: 'commessa',
          path: 'a-commessa',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/commessa/list/list-commessa.module#ListCommessaModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_COMMESSA,
              deleteUrl: Constants.ELIMINA_COMMESSA,
              editUrl: AppConstants.COMMESSA_EDIT_URL,
              excelUrl: Constants.EXCEL_COMMESSA,
              excelTitle: 'Excel Commessa',
              searchUrl: Constants.SEARCH_COMMESSA,
              listName: 'commesse',
              title: 'commessa.list.titolo',
              path: 'a-commessa/list',
              showBack: false,
            },
          },
          {
            path: 'associa-dipendente',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/commessa/associa-dipendente/associa-dipendente-commessa.module#AssociaDipendenteCommessaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'commessa.associaDipendente.titolo',
              urlToCall: Constants.CREA_COMMESSE_DIPENDENTE,
              path: 'a-commessa/associa-dipendente',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modificaCommessaDipendente',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/commessa/associa-dipendente/associa-dipendente-commessa.module#AssociaDipendenteCommessaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'commessa.associaDipendente.titoloModifica',
              urlToCall: Constants.MODIFICA_COMMESSE_DIPENDENTE,
              getUrl: Constants.GET_COMMESSE_DIPENDENTE,
              path: 'a-commessa/modificaCommessaDipendente',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/commessa/crea/crea-commessa.module#CreaCommessaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'commessa.creaModifica.titoloCrea',
              urlToCall: Constants.CREA_COMMESSA,
              path: 'a-commessa/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/commessa/crea/crea-commessa.module#CreaCommessaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'commessa.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_COMMESSA,
              getUrl: Constants.GET_COMMESSA,
              path: 'a-commessa/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'list-commesse-dipendenti',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/commessa/list-commesse-dipendenti/list-commesse-dipendenti.module#ListCommesseDipendentiModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_COMMESSE_DIPENDENTE,
              deleteUrl: Constants.ELIMINA_COMMESSE_DIPENDENTE,
              excelUrl: Constants.EXCEL_COMMESSE_DIPENDENTE,
              excelTitle: 'Excel Commesse Dipendente',
              editUrl: AppConstants.COMMESSA_DIPENDENTE_EDIT_URL,
              searchUrl: Constants.SEARCH_COMMESSE_DIPENDENTE,
              listName: 'commesseDipendente',
              title: 'commessa.listCommesseDipendenti.titolo',
              hideResetOrder: true,
              path: 'a-commessa/list-commesse-dipendenti',
              showBack: false,
            },
          },
        ],
      },
      {
        path: 'a-strumento',
        data: {
          title: 'strumento',
          path: 'a-strumento',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/strumento/list/list-strumento.module#ListStrumentoModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_STRUMENTO,
              deleteUrl: Constants.ELIMINA_STRUMENTO,
              editUrl: AppConstants.STRUMENTO_EDIT_URL,
              excelUrl: Constants.EXCEL_STRUMENTO,
              excelTitle: 'Excel Strumento',
              searchUrl: Constants.SEARCH_STRUMENTO,
              listName: 'strumenti',
              title: 'strumenti.list.titolo',
              path: 'a-strumento/list',
              showBack: false,
            },
          },
          {
            path: 'associa-strumento-dipendente',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/strumento/associa-strumento-dipendente/associa-strumento-dipendente.module#AssociStrumentoDipendenteModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'strumenti.associaStrumento.titolo',
              urlToCall: Constants.CREA_STRUMENTO_DIPENDENTE,
              path: 'a-strumento/associa-strumento-dipendente',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica-strumento-dipendente',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/strumento/associa-strumento-dipendente/associa-strumento-dipendente.module#AssociStrumentoDipendenteModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'strumenti.associaStrumento.titoloModifica',
              urlToCall: Constants.MODIFICA_STRUMENTO_DIPENDENTE,
              getUrl: Constants.GET_STRUMENTO_DIPENDENTE,
              path: 'a-strumento/modifica-strumento-dipendente',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/strumento/crea/crea-strumento.module#CreaStrumentoModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'strumenti.creaModifica.titoloCrea',
              urlToCall: Constants.CREA_STRUMENTO,
              path: 'a-strumento/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/strumento/crea/crea-strumento.module#CreaStrumentoModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'strumenti.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_STRUMENTO,
              getUrl: Constants.GET_STRUMENTO,
              path: 'a-strumento/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'list-strumento-dipendente',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/strumento/list-strumento-dipendente/list-strumento-dipendente.module#ListStrumentoDipendenteModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_STRUMENTO_DIPENDENTE,
              deleteUrl: Constants.ELIMINA_STRUMENTO_DIPENDENTE,
              excelUrl: Constants.EXCEL_STRUMENTO_DIPENDENTE,
              excelTitle: 'Excel Strumento Dipendente',
              editUrl: AppConstants.STRUMENTO_DIPENDENTE_EDIT_URL,
              searchUrl: Constants.SEARCH_STRUMENTO_DIPENDENTE,
              listName: 'strumentiDipendente',
              title: 'strumenti.listStrumentiDipendenti.titolo',
              hideResetOrder: true,
              path: 'a-strumento/list-strumento-dipendente',
              showBack: false,
            },
          },
        ],
      },
      {
        path: 'a-competenza',
        data: {
          title: 'competenza',
          path: 'a-competenza',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/competenza/list/list-competenza.module#ListCompetenzaModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_COMPETENZA,
              deleteUrl: Constants.ELIMINA_COMPETENZA,
              excelUrl: Constants.EXCEL_COMPETENZA,
              excelTitle: 'Excel Competenza',
              editUrl: AppConstants.COMPETENZA_EDIT_URL,
              searchUrl: Constants.SEARCH_COMPETENZA,
              listName: 'competenze',
              title: 'competenza.list.titolo',
              path: 'a-competenza/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/competenza/crea/crea-competenza.module#CreaCompetenzaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'competenza.creaModifica.titoloCrea',
              urlToCall: Constants.CREA_COMPETENZA,
              path: 'a-competenza/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/competenza/crea/crea-competenza.module#CreaCompetenzaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'competenza.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_COMPETENZA,
              getUrl: Constants.GET_COMPETENZA,
              path: 'a-competenza/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-notifica',
        data: {
          title: 'notifica',
          path: 'a-notifica',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/notifica/list/list-notifica.module#ListNotificaModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_NOTIFICA,
              deleteUrl: Constants.ELIMINA_NOTIFICA,
              excelUrl: Constants.EXCEL_NOTIFICA,
              excelTitle: 'Excel Notifica',
              editUrl: AppConstants.NOTIFICA_EDIT_URL,
              searchUrl: Constants.SEARCH_NOTIFICA,
              listName: 'notificheList',
              title: 'Notifiche',
              showActions: false,
              path: 'a-notifica/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/notifica/crea/crea-notifica.module#CreaNotificaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'Crea Notifica',
              urlToCall: Constants.CREA_NOTIFICA,
              path: 'a-notifica/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/notifica/crea/crea-notifica.module#CreaNotificaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'Modifica Notifica',
              urlToCall: Constants.MODIFICA_NOTIFICA,
              getUrl: Constants.GET_NOTIFICA,
              path: 'a-notifica/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-scadenzario',
        data: {
          title: 'scadenzario',
          path: 'a-scadenzario',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/scadenzario/list/list-scadenzario.module#ListScadenzarioModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_SCADENZARIO,
              deleteUrl: Constants.ELIMINA_SCADENZARIO,
              excelUrl: Constants.EXCEL_SCADENZARIO,
              excelTitle: 'Excel Scadenzario',
              editUrl: AppConstants.SCADENZARIO_EDIT_URL,
              searchUrl: Constants.SEARCH_SCADENZARIO,
              listName: 'scadenzari',
              title: 'Scadenzari',
              path: 'a-scadenzario/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/scadenzario/crea/crea-scadenzario.module#CreaScadenzarioModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'Crea Scadenzario',
              urlToCall: Constants.CREA_SCADENZARIO,
              path: 'a-scadenzario/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/scadenzario/crea/crea-scadenzario.module#CreaScadenzarioModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'Modifica Scadenzario',
              urlToCall: Constants.MODIFICA_SCADENZARIO,
              getUrl: Constants.GET_SCADENZARIO,
              path: 'a-scadenzario/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-situazione-aziendale',
        data: {
          title: 'situazione aziendale',
          path: 'a-situazione-aziendale',
          showBack: false,
        },
        children: [
          {
            path: 'resoconto',
            loadChildren:
              './pages/administrator-page/situazione-aziendale/resoconto/resoconto.module#ResocontoModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              path: 'a-situazione-aziendale/resoconto',
              showBack: false,
            },
          },
          {
            path: 'iva',
            loadChildren:
              './pages/administrator-page/situazione-aziendale/iva/iva.module#IvaModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              path: 'a-situazione-aziendale/iva',
              showBack: false,
            },
          },
          {
            path: 'compare',
            loadChildren:
              './pages/administrator-page/situazione-aziendale/comparatore/comparatore.module#ComparatoreModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              path: 'a-situazione-aziendale/compare',
              showBack: false,
            },
          },
        ],
      },
      {
        path: 'a-competenze-dipendente',
        data: {
          title: 'competenzeDipendente',
          path: 'a-competenze-dipendente',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/competenze-dipendente/list/list-competenze-dipendente.module#ListCompetenzeDipendenteModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_COMPETENZE_DIPENDENTE,
              deleteUrl: Constants.ELIMINA_COMPETENZE_DIPENDENTE,
              excelUrl: Constants.EXCEL_COMPETENZE_DIPENDENTE,
              excelTitle: 'Excel Competenze Dipendenti',
              editUrl: AppConstants.COMPETENZA_DIPENDENTE_EDIT_URL,
              searchUrl: Constants.SEARCH_COMPETENZE_DIPENDENTE,
              listName: 'competenzeDipendente',
              title: 'competenzeDipendente.list.titolo',
              path: 'a-competenze-dipendente/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/competenze-dipendente/crea/crea-competenze-dipendente.module#CreaCompetenzeDipendenteModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'competenzeDipendente.crea.titolo',
              urlToCall: Constants.CREA_COMPETENZE_DIPENDENTE,
              path: 'a-competenze-dipendente/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/competenze-dipendente/crea/crea-competenze-dipendente.module#CreaCompetenzeDipendenteModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'contratto.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_COMPETENZE_DIPENDENTE,
              getUrl: Constants.GET_COMPETENZE_DIPENDENTE,
              path: 'a-competenze-dipendente/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-stipendi-dipendenti',
        data: {
          title: 'Stipendi Dipendenti',
          path: 'a-stipendi-dipendenti',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/stipendi-dipendenti/list/list-stipendi-dipendenti.module#ListStipendiDipendentiModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_STIPENDI_DIPENDENTI,
              deleteUrl: Constants.ELIMINA_STIPENDI_DIPENDENTI,
              excelUrl: Constants.EXCEL_STIPENDI_DIPENDENTI,
              excelTitle: 'Excel Stipendi Dipendenti',
              editUrl: AppConstants.STIPENDI_DIPENDENTI_EDIT_URL,
              searchUrl: Constants.SEARCH_STIPENDI_DIPENDENTI,
              listName: 'stipendiDipendenti',
              title: 'stipendi-dipendenti.list.titolo',
              uploadUrl: Constants.UPLOAD_STIPENDI_DIPENDENTI,
              deleteFileUrl: Constants.ELIMINA_FILE_STIPENDI_DIPENDENTI,
              downloadUrl: Constants.DOWNLOAD_STIPENDI_DIPENDENTI,
              supportedFiles: '.pdf',
              showUploadButton: true,
              showDownloadButton: true,
              showDeleteButton: true,
              path: 'a-stipendi-dipendenti/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/stipendi-dipendenti/crea/crea-stipendi-dipendenti.module#CreaStipendiDipendentiModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'stipendi-dipendenti.creaModifica.titoloCrea',
              urlToCall: Constants.CREA_STIPENDI_DIPENDENTI,
              path: 'a-stipendi-dipendenti/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/stipendi-dipendenti/crea/crea-stipendi-dipendenti.module#CreaStipendiDipendentiModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'stipendi-dipendenti.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_STIPENDI_DIPENDENTI,
              getUrl: Constants.GET_STIPENDI_DIPENDENTI,
              path: 'a-stipendi-dipendenti/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-cu',
        data: {
          title: 'Cu',
          path: 'a-cu',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/cu/list/list-cu.module#ListCuModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_CU,
              deleteUrl: Constants.ELIMINA_CU,
              excelUrl: Constants.EXCEL_CU,
              excelTitle: 'Excel Cu',
              editUrl: AppConstants.CU_EDIT_URL,
              searchUrl: Constants.SEARCH_CU,
              listName: 'cu',
              title: 'Cu',
              uploadUrl: Constants.UPLOAD_CU,
              deleteFileUrl: Constants.ELIMINA_FILE_CU,
              downloadUrl: Constants.DOWNLOAD_CU,
              supportedFiles: '.pdf',
              showUploadButton: true,
              showDownloadButton: true,
              showDeleteButton: true,
              path: 'a-cu/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/cu/crea/crea-cu.module#CreaCuModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'Crea Cu',
              urlToCall: Constants.CREA_CU,
              path: 'a-cu/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/cu/crea/crea-cu.module#CreaCuModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'Modifica Cu',
              urlToCall: Constants.MODIFICA_CU,
              getUrl: Constants.GET_CU,
              path: 'a-cu/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-entrate-uscite',
        data: {
          title: 'entrate',
          path: 'a-entrate-uscite',
          showBack: false,
        },
        children: [
          {
            path: 'list-entrate',
            loadChildren:
              './pages/administrator-page/entrate-uscite/list-entrate/list-entrate.module#ListEntrateModule',
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_ENTRATE_USCITE,
              deleteUrl: Constants.ELIMINA_ENTRATA_USCITA,
              excelUrl: Constants.EXCEL_ENTRATA_USCITA,
              excelTitle: 'Excel Entrate Generiche',
              editUrl: AppConstants.ENTRATE_EDIT_URL,
              searchUrl: Constants.SEARCH_ENTRATA_USCITA,
              listName: 'entrateUscite',
              title: 'entrateUscite.list.entrate',
              path: 'a-entrate-uscite/list-entrate',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'list-uscite',
            loadChildren:
              './pages/administrator-page/entrate-uscite/list-uscite/list-uscite.module#ListUsciteModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_ENTRATE_USCITE,
              deleteUrl: Constants.ELIMINA_ENTRATA_USCITA,
              excelUrl: Constants.EXCEL_ENTRATA_USCITA,
              excelTitle: 'Excel Uscite Generiche',
              editUrl: AppConstants.USCITE_EDIT_URL,
              searchUrl: Constants.SEARCH_ENTRATA_USCITA,
              listName: 'entrateUscite',
              title: 'entrateUscite.list.uscite',
              path: 'a-entrate-uscite/list-uscite',
              showBack: false,
            },
          },
          {
            path: 'crea-entrata',
            loadChildren:
              './pages/administrator-page/entrate-uscite/crea-entrata/crea-entrata.module#CreaEntrataModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'entrateUscite.creaModifica.titoloCreaEntrate',
              urlToCall: Constants.CREA_ENTRATA_USCITA,
              path: 'a-entrate-uscite/crea-entrata',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'crea-uscita',
            loadChildren:
              './pages/administrator-page/entrate-uscite/crea-uscita/crea-uscita.module#CreaUscitaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'entrateUscite.creaModifica.titoloCreaUscite',
              urlToCall: Constants.CREA_ENTRATA_USCITA,
              path: 'a-entrate-uscite/crea-uscita',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica-entrata',
            loadChildren:
              './pages/administrator-page/entrate-uscite/crea-entrata/crea-entrata.module#CreaEntrataModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'entrateUscite.creaModifica.titoloModificaEntrate',
              urlToCall: Constants.MODIFICA_ENTRATA_USCITA,
              getUrl: Constants.GET_ENTRATE_USCITE,
              path: 'a-entrate-uscite/modifica-entrata',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica-uscita',
            loadChildren:
              './pages/administrator-page/entrate-uscite/crea-uscita/crea-uscita.module#CreaUscitaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'entrateUscite.creaModifica.titoloModificaUscite',
              urlToCall: Constants.MODIFICA_ENTRATA_USCITA,
              getUrl: Constants.GET_ENTRATE_USCITE,
              path: 'a-entrate-uscite/modifica-uscita',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-fattura',
        data: {
          title: 'fattura',
          path: 'a-fattura',
          showBack: false,
        },
        children: [
          {
            path: 'list-emesse',
            loadChildren:
              './pages/administrator-page/fattura/list-emessa/list-fattura-emessa.module#ListFatturaEmessaModule',
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_FATTURA,
              deleteUrl: Constants.ELIMINA_FATTURA,
              excelUrl: Constants.EXCEL_FATTURA,
              excelTitle: 'Excel Fatture Emesse',
              editUrl: AppConstants.FATTURA_EMESSA_EDIT_URL,
              searchUrl: Constants.SEARCH_FATTURA,
              listName: 'fatture',
              title: 'fattura.list.fattureEmesse',
              uploadUrl: Constants.UPLOAD_FATTURA,
              deleteFileUrl: Constants.ELIMINA_FILE_FATTURA,
              downloadUrl: Constants.DOWNLOAD_FATTURA,
              supportedFiles: '.pdf',
              showUploadButton: true,
              showDownloadButton: true,
              showDeleteButton: true,
              path: 'a-fattura/list-emesse',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'list-ricevute',
            loadChildren:
              './pages/administrator-page/fattura/list-ricevuta/list-fattura-ricevuta.module#ListFatturaRicevutaModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_FATTURA,
              deleteUrl: Constants.ELIMINA_FATTURA,
              excelUrl: Constants.EXCEL_FATTURA,
              excelTitle: 'Excel Fatture ricevute',
              editUrl: AppConstants.FATTURA_RICEVUTA_EDIT_URL,
              searchUrl: Constants.SEARCH_FATTURA,
              listName: 'fatture',
              title: 'fattura.list.fattureRicevute',
              uploadUrl: Constants.UPLOAD_FATTURA,
              deleteFileUrl: Constants.ELIMINA_FILE_FATTURA,
              downloadUrl: Constants.DOWNLOAD_FATTURA,
              supportedFiles: '.pdf',
              showUploadButton: true,
              showDownloadButton: true,
              showDeleteButton: true,
              path: 'a-fattura/list-ricevute',
              showBack: false,
            },
          },
          {
            path: 'crea-emessa',
            loadChildren:
              './pages/administrator-page/fattura/crea-emessa/crea-fattura-emessa.module#CreaFatturaEmessaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'Crea Fattura Emessa',
              urlToCall: Constants.CREA_FATTURA,
              path: 'a-fattura/crea-emessa',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'crea-ricevuta',
            loadChildren:
              './pages/administrator-page/fattura/crea-ricevuta/crea-fattura-ricevuta.module#CreaFatturaRicevutaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'Crea Fattura Ricevuta',
              urlToCall: Constants.CREA_FATTURA,
              path: 'a-fattura/crea-ricevuta',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica-ricevuta',
            loadChildren:
              './pages/administrator-page/fattura/crea-ricevuta/crea-fattura-ricevuta.module#CreaFatturaRicevutaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'Modifica Fattura Ricevuta',
              urlToCall: Constants.MODIFICA_FATTURA,
              getUrl: Constants.GET_FATTURA,
              path: 'a-fattura/modifica-ricevuta',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica-emessa',
            loadChildren:
              './pages/administrator-page/fattura/crea-emessa/crea-fattura-emessa.module#CreaFatturaEmessaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'Modifica Fattura Emessa',
              urlToCall: Constants.MODIFICA_FATTURA,
              getUrl: Constants.GET_FATTURA,
              path: 'a-fattura/modifica-emessa',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'list-tipi-pagamento',
            loadChildren:
              './pages/administrator-page/tipo-pagamento/list/list-tipo-pagamento.module#ListTipoPagamentoModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_TIPO_PAGAMENTO,
              deleteUrl: Constants.ELIMINA_TIPO_PAGAMENTO,
              excelUrl: Constants.EXCEL_TIPO_PAGAMENTO,
              excelTitle: 'Excel Tipi Pagamenti',
              editUrl: AppConstants.TIPO_PAGAMENTO_EDIT_URL,
              searchUrl: Constants.SEARCH_TIPO_PAGAMENTO,
              listName: 'tipoPagamentoList',
              title: 'tipoPagamento.list.titolo',
              path: 'a-fattura/list-tipi-pagamento',
              showBack: false,
            },
          },
          {
            path: 'modifica-tipo-pagamento',
            loadChildren:
              './pages/administrator-page/tipo-pagamento/crea/crea-tipo-pagamento.module#CreaTipoPagamentoModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'tipoPagamento.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_TIPO_PAGAMENTO,
              getUrl: Constants.GET_TIPO_PAGAMENTO,
              path: 'a-fattura/modifica-tipo-pagamento',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'crea-tipo-pagamento',
            loadChildren:
              './pages/administrator-page/tipo-pagamento/crea/crea-tipo-pagamento.module#CreaTipoPagamentoModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'tipoPagamento.creaModifica.titoloCrea',
              urlToCall: Constants.CREA_TIPO_PAGAMENTO,
              path: 'a-fattura/crea-tipo-pagamento',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'list-tipi-fattura',
            loadChildren:
              './pages/administrator-page/tipo-fattura/list/list-tipo-fattura.module#ListTipoFatturaModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_TIPO_FATTURA,
              deleteUrl: Constants.ELIMINA_TIPO_FATTURA,
              excelUrl: Constants.EXCEL_TIPO_FATTURA,
              excelTitle: 'Excel Tipi Fatture',
              editUrl: AppConstants.TIPO_FATTURA_EDIT_URL,
              searchUrl: Constants.SEARCH_TIPO_FATTURA,
              listName: 'tipoFatturaList',
              title: 'tipoFattura.list.titolo',
              path: 'a-fattura/list-tipi-fattura',
              showBack: false,
            },
          },
          {
            path: 'modifica-tipo-fattura',
            loadChildren:
              './pages/administrator-page/tipo-fattura/crea/crea-tipo-fattura.module#CreaTipoFatturaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'tipoFattura.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_TIPO_FATTURA,
              getUrl: Constants.GET_TIPO_FATTURA,
              path: 'a-fattura/modifica-tipo-fattura',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'crea-tipo-fattura',
            loadChildren:
              './pages/administrator-page/tipo-fattura/crea/crea-tipo-fattura.module#CreaTipoFatturaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'tipoFattura.creaModifica.titoloCrea',
              urlToCall: Constants.CREA_TIPO_FATTURA,
              path: 'a-fattura/crea-tipo-fattura',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-fornitore',
        data: {
          title: 'fornitore',
          path: 'a-fornitore',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/fornitore/list/list-fornitore.module#ListFornitoreModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_FORNITORE,
              deleteUrl: Constants.ELIMINA_FORNITORE,
              excelUrl: Constants.EXCEL_FORNITORE,
              excelTitle: 'Excel Fornitori',
              editUrl: AppConstants.FORNITORE_EDIT_URL,
              searchUrl: Constants.SEARCH_FORNITORE,
              listName: 'fornitori',
              title: 'fornitore.list.titolo',
              path: 'a-fornitore/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/fornitore/crea/crea-fornitore.module#CreaFornitoreModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'fornitore.creaModifica.titoloCrea',
              urlToCall: Constants.CREA_FORNITORE,
              path: 'a-fornitore/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/fornitore/crea/crea-fornitore.module#CreaFornitoreModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'fornitore.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_FORNITORE,
              getUrl: Constants.GET_FORNITORE,
              path: 'a-fornitore/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-ruolo',
        data: {
          title: 'ruolo',
          path: 'a-ruolo',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/ruolo/list/list-ruolo.module#ListRuoloModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_RUOLO,
              deleteUrl: Constants.ELIMINA_RUOLO,
              excelUrl: Constants.EXCEL_RUOLO,
              excelTitle: 'Excel Ruoli',
              editUrl: AppConstants.RUOLO_EDIT_URL,
              searchUrl: Constants.SEARCH_RUOLO,
              listName: 'ruoli',
              showActions: false,
              title: 'ruolo.list.titolo',
              path: 'a-ruolo/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/ruolo/crea/crea-ruolo.module#CreaRuoloModule',
            data: {
              exprectedPermission: '3',
              isModifica: false,
              title: 'ruolo.creaModifica.titoloCrea',
              urlToCall: Constants.CREA_RUOLO,
              path: 'a-ruolo/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/ruolo/crea/crea-ruolo.module#CreaRuoloModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'ruolo.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_RUOLO,
              getUrl: Constants.GET_RUOLO,
              path: 'a-ruolo/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-saldo-iniziale',
        data: {
          title: 'saldo iniziale',
          path: 'a-saldo-iniziale',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/saldo-iniziale/list/list-saldo-iniziale.module#ListSaldoInizialeModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_SALDO_INIZIALE,
              deleteUrl: Constants.ELIMINA_SALDO_INIZIALE,
              excelUrl: Constants.EXCEL_SALDO_INIZIALE,
              excelTitle: 'Excel Saldi Iniziali',
              editUrl: AppConstants.SALDO_INIZIALE_EDIT_URL,
              searchUrl: Constants.SEARCH_SALDO_INIZIALE,
              listName: 'saldoInizialeList',
              title: 'Saldi Iniziali',
              path: 'a-saldo-iniziale/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/saldo-iniziale/crea/crea-saldo-iniziale.module#CreaSaldoInizialeModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'Crea Saldo Iniziale',
              urlToCall: Constants.CREA_SALDO_INIZIALE,
              path: 'a-saldo-iniziale/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/saldo-iniziale/crea/crea-saldo-iniziale.module#CreaSaldoInizialeModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'Modifica Saldo Iniziale',
              urlToCall: Constants.MODIFICA_SALDO_INIZIALE,
              getUrl: Constants.GET_SALDO_INIZIALE,
              path: 'a-saldo-iniziale/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-ruolo-professionale',
        data: {
          title: 'ruolo professionale',
          path: 'a-ruolo-professionale',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/ruolo-professionale/list/list-ruolo-professionale.module#ListRuoloProfessionaleModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_RUOLO_PROFESSIONALE,
              deleteUrl: Constants.ELIMINA_RUOLO_PROFESSIONALE,
              excelUrl: Constants.EXCEL_RUOLO_PROFESSIONALE,
              excelTitle: 'Excel Ruoli Professionali',
              editUrl: AppConstants.RUOLO_PROFESSIONALE_EDIT_URL,
              searchUrl: Constants.SEARCH_RUOLO_PROFESSIONALE,
              listName: 'ruoliProfessionaliList',
              title: 'Ruoli Professionali',
              path: 'a-ruolo-professionale/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/ruolo-professionale/crea/crea-ruolo-professionale.module#CreaRuoloProfessionaleModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'Crea Ruolo Professionale',
              urlToCall: Constants.CREA_RUOLO_PROFESSIONALE,
              path: 'a-ruolo-professionale/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/ruolo-professionale/crea/crea-ruolo-professionale.module#CreaRuoloProfessionaleModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'Modifica Ruolo Professionale',
              urlToCall: Constants.MODIFICA_RUOLO_PROFESSIONALE,
              getUrl: Constants.GET_RUOLO_PROFESSIONALE,
              path: 'a-ruolo-professionale/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-contratto',
        data: {
          title: 'contratto',
          path: 'a-contratto',
          showBack: false,
        },
        children: [
          {
            path: 'list-contratto-cliente',
            loadChildren:
              './pages/administrator-page/contratto/list-cliente/list-contratto-cliente.module#ListContrattoClienteModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_CONTRATTO,
              deleteUrl: Constants.ELIMINA_CONTRATTO,
              excelUrl: Constants.EXCEL_CONTRATTO,
              excelTitle: 'Excel Contratti Clienti',
              editUrl: AppConstants.CONTRATTO_CLIENTE_EDIT_URL,
              searchUrl: Constants.SEARCH_CONTRATTO,
              listName: 'contratti',
              title: 'contratto.list.titoloCliente',
              uploadUrl: Constants.UPLOAD_CONTRATTO,
              deleteFileUrl: Constants.ELIMINA_FILE_CONTRATTO,
              downloadUrl: Constants.DOWNLOAD_CONTRATTO,
              supportedFiles: '.pdf',
              showUploadButton: true,
              showDownloadButton: true,
              showDeleteButton: true,
              path: 'a-contratto/list-contratto-cliente',
              showBack: false,
            },
          },
          {
            path: 'crea-contratto-cliente',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/contratto/crea-contratto-cliente/crea-contratto-cliente.module#CreaContrattoClienteModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'contratto.creaModifica.titoloCreaCliente',
              urlToCall: Constants.CREA_CONTRATTO,
              path: 'a-contratto/crea-contratto-cliente',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica-contratto-cliente',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/contratto/crea-contratto-cliente/crea-contratto-cliente.module#CreaContrattoClienteModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'contratto.creaModifica.titoloModificaCliente',
              urlToCall: Constants.MODIFICA_CONTRATTO,
              getUrl: Constants.GET_CONTRATTO,
              path: 'a-contratto/modifica-contratto-cliente',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },

          {
            path: 'list-contratto-fornitore',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/contratto/list-fornitore/list-contratto-fornitore.module#ListContrattoFornitoreModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_CONTRATTO,
              excelUrl: Constants.EXCEL_CONTRATTO,
              deleteUrl: Constants.ELIMINA_CONTRATTO,
              excelTitle: 'Excel Contratti Fornitori',
              editUrl: AppConstants.CONTRATTO_FORNITORE_EDIT_URL,
              searchUrl: Constants.SEARCH_CONTRATTO,
              listName: 'contratti',
              title: 'contratto.list.titoloFornitore',
              uploadUrl: Constants.UPLOAD_CONTRATTO,
              deleteFileUrl: Constants.ELIMINA_FILE_CONTRATTO,
              downloadUrl: Constants.DOWNLOAD_CONTRATTO,
              supportedFiles: '.pdf',
              showUploadButton: true,
              showDownloadButton: true,
              showDeleteButton: true,
              path: 'a-contratto/list-contratto-fornitore',
              showBack: false,
            },
          },
          {
            path: 'crea-contratto-fornitore',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/contratto/crea-contratto-fornitore/crea-contratto-fornitore.module#CreaContrattoFornitoreModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'contratto.creaModifica.titoloCreaFornitore',
              urlToCall: Constants.CREA_CONTRATTO,
              path: 'a-contratto/crea-contratto-fornitore',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica-contratto-fornitore',
            // tslint:disable-next-line: max-line-length
            loadChildren:
              './pages/administrator-page/contratto/crea-contratto-fornitore/crea-contratto-fornitore.module#CreaContrattoFornitoreModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'contratto.creaModifica.titoloModificaFornitore',
              urlToCall: Constants.MODIFICA_CONTRATTO,
              getUrl: Constants.GET_CONTRATTO,
              path: 'a-contratto/modifica-contratto-fornitore',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-utente',
        data: {
          title: 'utente',
          path: 'a-utente',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/utente/list/list-utente.module#ListUtenteModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_UTENTE,
              deleteUrl: Constants.ELIMINA_UTENTE,
              excelUrl: Constants.EXCEL_UTENTE,
              excelTitle: 'Excel Utenti',
              editUrl: AppConstants.UTENTE_EDIT_URL,
              searchUrl: Constants.SEARCH_UTENTE,
              listName: 'utenti',
              title: 'utente.list.titolo',
              hideResetOrder: true,
              path: 'a-utente/list',
              showBack: false,
            },
          },
          {
            path: 'list-dipendente',
            loadChildren:
              './pages/administrator-page/utente/list-dipendente/list-dipendente.module#ListDipendenteModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_DIPENDENTE,
              excelUrl: Constants.EXCEL_DIPENDENTE,
              excelTitle: 'Excel Dipendenti',
              deleteUrl: Constants.ELIMINA_DIPENDENTE,
              editUrl: AppConstants.DIPENDENTE_EDIT_URL,
              searchUrl: Constants.SEARCH_DIPENDENTE,
              listName: 'dipendenti',
              title: 'dipendente.list.titolo',
              customTitle: 'Mostra Competenze',
              customIcon: 'fa fa-pencil-square-o',
              customButtonMethodName: 'goToMostraCompetenze',
              showCustomButton: true,
              hideDeleteButton: true,
              hideModifyButton: true,
              path: 'a-utente/list-dipendente',
              showBack: false,
            },
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/utente/crea/crea-utente.module#CreaUtenteModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'utente.creaModifica.titoloCrea',
              urlToCall: Constants.CREA_UTENTE,
              path: 'a-utente/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/utente/crea/crea-utente.module#CreaUtenteModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'utente.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_UTENTE,
              getUrl: Constants.GET_UTENTE,
              path: 'a-utente/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-societa',
        data: {
          title: 'societa',
          path: 'a-societa',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/societa/list/list-societa.module#ListSocietaModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_SOCIETA,
              deleteUrl: Constants.ELIMINA_SOCIETA,
              excelUrl: Constants.EXCEL_SOCIETA,
              excelTitle: 'Excel Elenco Societa',
              editUrl: AppConstants.SOCIETA_EDIT_URL,
              searchUrl: Constants.SEARCH_SOCIETA,
              listName: 'societa',
              title: 'societa.list.titolo',
              path: 'a-societa/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/societa/crea/crea-societa.module#CreaSocietaModule',
            data: {
              exprectedPermission: '3',
              isModifica: false,
              title: 'societa.creaModifica.titoloCrea',
              urlToCall: Constants.CREA_SOCIETA,
              path: 'a-societa/crea',
              showBack: false,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/societa/crea/crea-societa.module#CreaSocietaModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'societa.creaModifica.titoloModifica',
              urlToCall: Constants.MODIFICA_SOCIETA,
              getUrl: Constants.GET_SOCIETA,
              path: 'a-societa/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-f24',
        data: {
          title: 'f24',
          path: 'a-f24',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/f24/list/list-f24.module#ListF24Module',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_F24,
              deleteUrl: Constants.ELIMINA_F24,
              excelUrl: Constants.EXCEL_F24,
              excelTitle: 'Excel Elenco F24',
              editUrl: AppConstants.F24_EDIT_URL,
              searchUrl: Constants.SEARCH_F24,
              listName: 'F24List',
              title: 'f24.list.titolo',
              uploadUrl: Constants.UPLOAD_F24,
              deleteFileUrl: Constants.ELIMINA_FILE_F24,
              downloadUrl: Constants.DOWNLOAD_F24,
              supportedFiles: '.pdf',
              showUploadButton: true,
              showDownloadButton: true,
              showDeleteButton: true,
              path: 'a-f24/list',
              showBack: false,
            },
          },
          {
            path: 'crea',
            loadChildren:
              './pages/administrator-page/f24/crea/crea-f24.module#CreaF24Module',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              isModifica: false,
              title: 'Crea F24',
              urlToCall: Constants.CREA_F24,
              path: 'a-f24/crea',
              showBack: false,
            },
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/f24/crea/crea-f24.module#CreaF24Module',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'Modifica F24',
              urlToCall: Constants.MODIFICA_F24,
              getUrl: Constants.GET_F24,
              path: 'a-f24/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-timesheet',
        data: {
          title: 'timesheet',
          path: 'a-timesheet',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/timesheets/list/list-timesheet.module#ListTimesheetModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              getUrl: Constants.GET_TIMESHEET,
              deleteUrl: Constants.ELIMINA_TIMESHEET,
              excelUrl: Constants.EXCEL_TIMESHEET,
              excelTitle: 'Excel Timesheets',
              editUrl: AppConstants.TIMESHEET_ADMIN_EDIT_URL,
              hideDetailButton: true,
              searchUrl: Constants.SEARCH_TIMESHEET,
              listName: 'timesheets',
              title: 'Timesheets',
              path: 'a-timesheet/list',
              showBack: false,
            },
          },
          {
            path: 'modifica',
            loadChildren:
              './pages/administrator-page/timesheets/crea/crea-timesheet.module#CreaTimesheetModule',
            data: {
              exprectedPermission: '1,3',
              isModifica: true,
              title: 'Modifica Timesheet',
              urlToCall: Constants.MODIFICA_TIMESHEET,
              getUrl: Constants.GET_TIMESHEET,
              path: 'a-timesheet/modifica',
              showBack: true,
            },
            canActivate: [AuthGuard, RoleGuard],
          },
        ],
      },
      {
        path: 'a-prima-nota',
        data: {
          title: 'Prima Nota',
          path: 'a-prima-nota',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/administrator-page/prima-nota/list/list-prima-nota.module#ListPrimaNotaModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,3',
              excelUrl: Constants.EXCEL_PRIMA_NOTA,
              excelTitle: 'PRIMA_NOTA',
              title: 'Prima Nota',
              path: 'a-prima-nota/list',
              showBack: false,
            },
          },
        ],
      },

      // DIPENDENTE
      {
        path: 'dashboard-dipendente',
        component: DashboardDipendenteComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          exprectedPermission: '1,2,3,6',
          path: 'dashboard-dipendente',
          showBack: false,
        },
      },
      {
        path: 'timesheet',
        data: {
          title: 'Timesheet',
          path: 'timesheet',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/dipendente-page/my-timesheet/my-timesheet.module#MyTimesheetModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,2,3,6',
              getUrl: Constants.GET_TIMESHEET,
              deleteUrl: Constants.ELIMINA_TIMESHEET,
              editUrl: AppConstants.TIMESHEET_EDIT_URL,
              searchUrl: Constants.SEARCH_TIMESHEET,
              excelUrl: Constants.EXCEL_TIMESHEET,
              excelTitle: 'Excel Timesheets',
              listName: 'timesheets',
              title: 'My Timesheets',
              path: 'timesheet/list',
              showBack: false,
            },
          },
        ],
      },
      {
        path: 'my-competenze',
        data: {
          exprectedPermission: '1,2,3,6',
          title: 'Competenze',
          path: 'my-competenze',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/dipendente-page/my-competenze/my-competenze.module#MyCompetenzeModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,2,3,6',
              getUrl: Constants.GET_COMPETENZE_DIPENDENTE,
              deleteUrl: Constants.ELIMINA_COMPETENZE_DIPENDENTE,
              editUrl: AppConstants.COMPETENZA_DIPENDENTE_EDIT_URL,
              searchUrl: Constants.SEARCH_COMPETENZE_DIPENDENTE,
              listName: 'competenzeDipendente',
              title: 'My Competenze',
              path: 'my-competenze/list',
              showBack: false,
            },
          },
        ],
      },
      {
        path: 'buste-paga',
        data: {
          title: 'Buste Paga',
          path: 'buste-paga',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/dipendente-page/my-buste-paga/list/my-buste-paga.module#MyBustePagaModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,2,3,6',
              excelUrl: Constants.EXCEL_STIPENDI_DIPENDENTI,
              excelTitle: 'My-buste-paga',
              searchUrl: Constants.SEARCH_STIPENDI_DIPENDENTI,
              listName: 'stipendiDipendenti',
              title: 'My Buste paga',
              showActions: true,
              hideDeleteButton: true,
              hideModifyButton: true,
              downloadUrl: Constants.DOWNLOAD_STIPENDI_DIPENDENTI,
              showDownloadButton: true,
              showDeleteButton: false,
              path: 'buste-paga/list',
              showBack: false,
            },
          },
        ],
      },
      {
        path: 'cu',
        data: {
          title: 'Cu',
          path: 'cu',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/dipendente-page/my-cu/list/my-cu.module#MyCuModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,2,3,6',
              excelUrl: Constants.EXCEL_CU,
              excelTitle: 'My-cu',
              searchUrl: Constants.SEARCH_CU,
              listName: 'cu',
              title: 'My Cu',
              showActions: true,
              hideDeleteButton: true,
              hideModifyButton: true,
              downloadUrl: Constants.DOWNLOAD_CU,
              showDownloadButton: true,
              showDeleteButton: false,
              path: 'cu/list',
              showBack: false,
            },
          },
        ],
      },
      {
        path: 'my-notifiche',
        data: {
          title: 'My Notifiche',
          path: 'my-notifiche',
          showBack: false,
        },
        children: [
          {
            path: 'list',
            loadChildren:
              './pages/dipendente-page/my-notifiche/list/my-notifiche.module#MyNotificheModule',
            canActivate: [AuthGuard, RoleGuard],
            data: {
              exprectedPermission: '1,2,3,6',
              searchUrl: Constants.SEARCH_NOTIFICA,
              listName: 'notificheList',
              title: 'My Notifiche',
              showActions: true,
              hideExcel: true,
              customTitle: 'Imposta come Letta / Non Letta',
              customIcon: 'fa fa-pencil-square-o',
              customButtonMethodName: 'setNotificaToLettaNonLetta',
              optionalButtonMethodName: 'setAllNotificheToLette',
              showCustomButton: true,
              showOptionalButton: true,
              hideDeleteButton: true,
              hideModifyButton: true,
              path: 'my-notifiche/list',
              showBack: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    loadChildren: './+login/login.module#LoginModule',
    data: {
      customLayout: true,
      path: 'login',
      showBack: false,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
