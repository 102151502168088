import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PaginatoreModule } from '../paginatore/paginatore.module';
import { PxltkTableComponent } from './pxltk-table.component';

@NgModule({
  imports: [CommonModule, PaginatoreModule, TranslateModule],
  declarations: [PxltkTableComponent],
  exports: [PxltkTableComponent],
})
export class PxltkTableModule {}
