import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule as MkAccordionModule } from 'angular-admin-lte';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { PxltkFileUploadComponent } from './pxltk-file-upload.component';

@NgModule({
  imports: [
    CommonModule,
    MkAccordionModule,
    FormsModule,
    MatFileUploadModule,
    NgxSmartModalModule,
    TranslateModule,
  ],
  declarations: [PxltkFileUploadComponent],
  exports: [PxltkFileUploadComponent],
})
export class PxltkFileUploadModule {}
