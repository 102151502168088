import { Component } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { EncryptionService } from 'src/app/shared/shared-services/encryption.service';
import { SharedCallService } from 'src/app/shared/shared-services/shared-call.service';
import { SharedService } from 'src/app/shared/shared-services/shared.service';

// component che mostra un model di conferma per un eliminazione, premere su SI effettua una subscribe
@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent {
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private spinner: NgxSpinnerService,
    private sharedCallService: SharedCallService,
    private sharedService: SharedService,
    private encryptionService: EncryptionService
  ) {}

  deleteElement(idElement: number, deleteUrl: string, deleted: boolean) {
    this.ngxSmartModalService.getModal('deleteModal').close();
    this.spinner.show();
    this.sharedCallService.delete(idElement, deleted, deleteUrl).subscribe(
      (result) => {
        result = this.encryptionService.decrypt(result);
        this.spinner.hide();
        if (result) {
          this.sharedService.deleteEvent.next(true);
        }
      },
      (error) => {
        this.spinner.hide();
        this.sharedService.showKoAlert();
      }
    );
  }
}
