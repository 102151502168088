export enum PxltkFormType {
  PASSWORD = 'password',
  STAR_RATING = 'STAR_RATING',
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  COMBO = 'COMBO',
  DATE = 'DATE',
  DATELIMITED = 'DATELIMITED',
  DATETIME = 'DATETIME',
  MULTICOMBO = 'MULTICOMBO',
  MONTHDATE = 'MONTHDATE',
  FULLMONTHDATE = 'FULLMONTHDATE',
  YEARDATE = 'YEARDATE',
  HOURS = 'HOURS',
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
  CHECK = 'CHECK',
}
