import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class Constants {
  public static URL_BACK_END = environment.BE_BASE_URL;

  public static CREA_CLIENTE = 'api/cliente/crea';
  public static MODIFICA_CLIENTE = 'api/cliente/modifica';
  public static ELIMINA_CLIENTE = 'api/cliente/elimina';
  public static SEARCH_CLIENTE = 'api/cliente/search';
  public static EXCEL_CLIENTE = 'api/cliente/excel';
  public static GET_CLIENTE = 'api/cliente/get';

  public static CREA_TIMESHEET = 'api/timesheet/crea';
  public static MODIFICA_TIMESHEET = 'api/timesheet/modifica';
  public static ELIMINA_TIMESHEET = 'api/timesheet/elimina';
  public static SEARCH_TIMESHEET = 'api/timesheet/search';
  public static EXCEL_TIMESHEET = 'api/timesheet/excel';
  public static GET_TIMESHEET = 'api/timesheet/get';

  public static CREA_NOTIFICA = 'api/notifica/crea';
  public static MODIFICA_NOTIFICA = 'api/notifica/modifica';
  public static ELIMINA_NOTIFICA = 'api/notifica/elimina';
  public static SEARCH_NOTIFICA = 'api/notifica/search';
  public static EXCEL_NOTIFICA = 'api/notifica/excel';
  public static GET_NOTIFICA = 'api/notifica/get';
  public static GET_NOTIFICHE_NON_LETTE =
    'api/notifica/get-notifiche-non-lette';
  public static MODIFICA_TUTTE_NOTIFICHE_LETTE = 'api/notifica/modifica-tutte';

  public static CREA_SCADENZARIO = 'api/scadenzario/crea';
  public static MODIFICA_SCADENZARIO = 'api/scadenzario/modifica';
  public static ELIMINA_SCADENZARIO = 'api/scadenzario/elimina';
  public static SEARCH_SCADENZARIO = 'api/scadenzario/search';
  public static EXCEL_SCADENZARIO = 'api/scadenzario/excel';
  public static GET_SCADENZARIO = 'api/scadenzario/get';

  public static EXCEL_PRIMA_NOTA = 'api/prima-nota/excel';

  public static CREA_STIPENDI_DIPENDENTI = 'api/stipendi-dipendenti/crea';
  public static MODIFICA_STIPENDI_DIPENDENTI =
    'api/stipendi-dipendenti/modifica';
  public static ELIMINA_STIPENDI_DIPENDENTI = 'api/stipendi-dipendenti/elimina';
  public static SEARCH_STIPENDI_DIPENDENTI = 'api/stipendi-dipendenti/search';
  public static UPLOAD_STIPENDI_DIPENDENTI = 'api/stipendi-dipendenti/upload';
  public static ELIMINA_FILE_STIPENDI_DIPENDENTI =
    'api/stipendi-dipendenti/elimina-file';
  public static DOWNLOAD_STIPENDI_DIPENDENTI =
    'api/stipendi-dipendenti/download';
  public static EXCEL_STIPENDI_DIPENDENTI = 'api/stipendi-dipendenti/excel';
  public static GET_STIPENDI_DIPENDENTI = 'api/stipendi-dipendenti/get';
  public static GET_TOTALE_STIPENDI_DIPENDENTE_URL =
    'api/stipendi-dipendenti/get-totale-stipendi-dipendente';

  public static CREA_CU = 'api/cu/crea';
  public static MODIFICA_CU = 'api/cu/modifica';
  public static ELIMINA_CU = 'api/cu/elimina';
  public static SEARCH_CU = 'api/cu/search';
  public static UPLOAD_CU = 'api/cu/upload';
  public static ELIMINA_FILE_CU = 'api/cu/elimina-file';
  public static DOWNLOAD_CU = 'api/cu/download';
  public static EXCEL_CU = 'api/cu/excel';
  public static GET_CU = 'api/cu/get';

  public static CREA_COMMESSA = 'api/commessa/crea';
  public static MODIFICA_COMMESSA = 'api/commessa/modifica';
  public static ELIMINA_COMMESSA = 'api/commessa/elimina';
  public static EXCEL_COMMESSA = 'api/commessa/excel';
  public static SEARCH_COMMESSA = 'api/commessa/search';
  public static GET_COMMESSA = 'api/commessa/get';

  public static CREA_STRUMENTO = 'api/strumento/crea';
  public static MODIFICA_STRUMENTO = 'api/strumento/modifica';
  public static ELIMINA_STRUMENTO = 'api/strumento/elimina';
  public static EXCEL_STRUMENTO = 'api/strumento/excel';
  public static SEARCH_STRUMENTO = 'api/strumento/search';
  public static GET_STRUMENTO = 'api/strumento/get';

  public static CREA_COMMESSE_DIPENDENTE = 'api/commesse-dipendente/crea';
  public static MODIFICA_COMMESSE_DIPENDENTE =
    'api/commesse-dipendente/modifica';
  public static ELIMINA_COMMESSE_DIPENDENTE = 'api/commesse-dipendente/elimina';
  public static EXCEL_COMMESSE_DIPENDENTE = 'api/commesse-dipendente/excel';
  public static SEARCH_COMMESSE_DIPENDENTE = 'api/commesse-dipendente/search';
  public static SEARCH_COMMESSE_DIPENDENTE_BY_CLIENTE =
    'api/commesse-dipendente/search-by-cliente';
  public static GET_COMMESSE_DIPENDENTE = 'api/commesse-dipendente/get';

  public static CREA_STRUMENTO_DIPENDENTE = 'api/strumento-dipendente/crea';
  public static MODIFICA_STRUMENTO_DIPENDENTE =
    'api/strumento-dipendente/modifica';
  public static ELIMINA_STRUMENTO_DIPENDENTE =
    'api/strumento-dipendente/elimina';
  public static EXCEL_STRUMENTO_DIPENDENTE = 'api/strumento-dipendente/excel';
  public static SEARCH_STRUMENTO_DIPENDENTE = 'api/strumento-dipendente/search';
  public static GET_STRUMENTO_DIPENDENTE = 'api/strumento-dipendente/get';

  public static CREA_COMPETENZA = 'api/competenza/crea';
  public static MODIFICA_COMPETENZA = 'api/competenza/modifica';
  public static ELIMINA_COMPETENZA = 'api/competenza/elimina';
  public static EXCEL_COMPETENZA = 'api/competenza/excel';
  public static SEARCH_COMPETENZA = 'api/competenza/search';
  public static GET_COMPETENZA = 'api/competenza/get';

  public static CREA_COMPETENZE_DIPENDENTE = 'api/competenzeDipendente/crea';
  public static MODIFICA_COMPETENZE_DIPENDENTE =
    'api/competenzeDipendente/modifica';
  public static ELIMINA_COMPETENZE_DIPENDENTE =
    'api/competenzeDipendente/elimina';
  public static EXCEL_COMPETENZE_DIPENDENTE = 'api/competenzeDipendente/excel';
  public static SEARCH_COMPETENZE_DIPENDENTE =
    'api/competenzeDipendente/search';
  public static GET_COMPETENZE_DIPENDENTE = 'api/competenzeDipendente/get';

  public static CREA_DIPENDENTE = 'api/dipendente/crea';
  public static MODIFICA_DIPENDENTE = 'api/dipendente/modifica';
  public static ELIMINA_DIPENDENTE = 'api/dipendente/elimina';
  public static EXCEL_DIPENDENTE = 'api/dipendente/excel';
  public static SEARCH_DIPENDENTE = 'api/dipendente/search';
  public static GET_DIPENDENTE = 'api/dipendente/get';
  public static GET_NUMERO_LAVORATORI_URL =
    'api/dipendente/get-numero-lavoratori';

  public static CREA_FATTURA = 'api/fattura/crea';
  public static MODIFICA_FATTURA = 'api/fattura/modifica';
  public static ELIMINA_FATTURA = 'api/fattura/elimina';
  public static EXCEL_FATTURA = 'api/fattura/excel';
  public static SEARCH_FATTURA = 'api/fattura/search';
  public static UPLOAD_FATTURA = 'api/fattura/upload';
  public static ELIMINA_FILE_FATTURA = 'api/fattura/elimina-file';
  public static DOWNLOAD_FATTURA = 'api/fattura/download';
  public static GET_FATTURA = 'api/fattura/get';
  public static GET_NUMERO_FATTURE_SCADUTE_URL = 'api/fattura/get-fatt-scadute';
  public static COMPARE_FATTURATO_ANNUALE_URL =
    'api/fattura/compare-fatturato-annuale';

  public static CREA_ENTRATA_USCITA = 'api/entrate-uscite/crea';
  public static MODIFICA_ENTRATA_USCITA = 'api/entrate-uscite/modifica';
  public static ELIMINA_ENTRATA_USCITA = 'api/entrate-uscite/elimina';
  public static EXCEL_ENTRATA_USCITA = 'api/entrate-uscite/excel';
  public static SEARCH_ENTRATA_USCITA = 'api/entrate-uscite/search';
  public static GET_ENTRATE_USCITE = 'api/entrate-uscite/get';

  public static CREA_FORNITORE = 'api/fornitore/crea';
  public static MODIFICA_FORNITORE = 'api/fornitore/modifica';
  public static ELIMINA_FORNITORE = 'api/fornitore/elimina';
  public static EXCEL_FORNITORE = 'api/fornitore/excel';
  public static SEARCH_FORNITORE = 'api/fornitore/search';
  public static GET_FORNITORE = 'api/fornitore/get';

  public static CREA_RUOLO = 'api/ruolo/crea';
  public static MODIFICA_RUOLO = 'api/ruolo/modifica';
  public static ELIMINA_RUOLO = 'api/ruolo/elimina';
  public static EXCEL_RUOLO = 'api/ruolo/excel';
  public static SEARCH_RUOLO = 'api/ruolo/search';
  public static GET_RUOLO = 'api/ruolo/get';

  public static CREA_SALDO_INIZIALE = 'api/saldo-iniziale/crea';
  public static MODIFICA_SALDO_INIZIALE = 'api/saldo-iniziale/modifica';
  public static ELIMINA_SALDO_INIZIALE = 'api/saldo-iniziale/elimina';
  public static EXCEL_SALDO_INIZIALE = 'api/saldo-iniziale/excel';
  public static SEARCH_SALDO_INIZIALE = 'api/saldo-iniziale/search';
  public static GET_SALDO_INIZIALE = 'api/saldo-iniziale/get';

  public static CREA_RUOLO_PROFESSIONALE = 'api/ruolo-professionale/crea';
  public static MODIFICA_RUOLO_PROFESSIONALE =
    'api/ruolo-professionale/modifica';
  public static ELIMINA_RUOLO_PROFESSIONALE = 'api/ruolo-professionale/elimina';
  public static EXCEL_RUOLO_PROFESSIONALE = 'api/ruolo-professionale/excel';
  public static SEARCH_RUOLO_PROFESSIONALE = 'api/ruolo-professionale/search';
  public static GET_RUOLO_PROFESSIONALE = 'api/ruolo-professionale/get';

  public static CREA_SOCIETA = 'api/societa/crea';
  public static MODIFICA_SOCIETA = 'api/societa/modifica';
  public static ELIMINA_SOCIETA = 'api/societa/elimina';
  public static EXCEL_SOCIETA = 'api/societa/excel';
  public static SEARCH_SOCIETA = 'api/societa/search';
  public static GET_SOCIETA = 'api/societa/get';

  public static CREA_UTENTE = 'api/utente/crea';
  public static MODIFICA_UTENTE = 'api/utente/modifica';
  public static ELIMINA_UTENTE = 'api/utente/elimina';
  public static EXCEL_UTENTE = 'api/utente/excel';
  public static SEARCH_UTENTE = 'api/utente/search';
  public static GET_UTENTE = 'api/utente/get';
  public static REGISTRA_UTENTE = 'api/utente/utente/registra';

  public static CREA_TIPO_PAGAMENTO = 'api/tipoPagamento/crea';
  public static MODIFICA_TIPO_PAGAMENTO = 'api/tipoPagamento/modifica';
  public static ELIMINA_TIPO_PAGAMENTO = 'api/tipoPagamento/elimina';
  public static EXCEL_TIPO_PAGAMENTO = 'api/tipoPagamento/excel';
  public static SEARCH_TIPO_PAGAMENTO = 'api/tipoPagamento/search';
  public static GET_TIPO_PAGAMENTO = 'api/tipoPagamento/get';

  public static CREA_TIPO_FATTURA = 'api/tipoFattura/crea';
  public static MODIFICA_TIPO_FATTURA = 'api/tipoFattura/modifica';
  public static ELIMINA_TIPO_FATTURA = 'api/tipoFattura/elimina';
  public static EXCEL_TIPO_FATTURA = 'api/tipoFattura/excel';
  public static SEARCH_TIPO_FATTURA = 'api/tipoFattura/search';
  public static GET_TIPO_FATTURA = 'api/tipoFattura/get';

  public static CREA_CONTRATTO = 'api/contratto/crea';
  public static MODIFICA_CONTRATTO = 'api/contratto/modifica';
  public static ELIMINA_CONTRATTO = 'api/contratto/elimina';
  public static EXCEL_CONTRATTO = 'api/contratto/excel';
  public static SEARCH_CONTRATTO = 'api/contratto/search';
  public static GET_CONTRATTO = 'api/contratto/get';
  public static UPLOAD_CONTRATTO = 'api/contratto/upload';
  public static ELIMINA_FILE_CONTRATTO = 'api/contratto/elimina-file';
  public static DOWNLOAD_CONTRATTO = 'api/contratto/download';
  public static GET_NUMERO_CONTRATTI_URL = 'api/contratto/get-numero';

  public static CREA_F24 = 'api/f24/crea';
  public static MODIFICA_F24 = 'api/f24/modifica';
  public static ELIMINA_F24 = 'api/f24/elimina';
  public static EXCEL_F24 = 'api/f24/excel';
  public static SEARCH_F24 = 'api/f24/search';
  public static UPLOAD_F24 = 'api/f24/upload';
  public static ELIMINA_FILE_F24 = 'api/f24/elimina-file';
  public static DOWNLOAD_F24 = 'api/f24/download';
  public static GET_F24 = 'api/f24/get';

  public static LISTA_CLIENTI_COMBO_URL = 'api/select/clienti';
  public static LISTA_CONTRATTI_COMBO_URL = 'api/select/contratti';
  public static LISTA_TIPO_FATTURE_COMBO_URL = 'api/select/tipo-fatture';
  public static LISTA_TIPO_PAGAMENTI_COMBO_URL = 'api/select/tipo-pagamenti';
  public static LISTA_TIPO_RUOLO_COMBO_URL = 'api/select/tipo-ruolo';
  public static LISTA_DIPENDENTI_COMBO_URL = 'api/select/dipendenti';
  public static LISTA_SOCIETA_COMBO_URL = 'api/select/societa';
  public static LISTA_RUOLI_COMBO_URL = 'api/select/ruoli';
  public static LISTA_RUOLI_PROFESSIONALI_COMBO_URL =
    'api/select/ruoli-professionali';
  public static LISTA_FORNITORI_COMBO_URL = 'api/select/fornitori';
  public static LISTA_COMPETENZE_COMBO_URL = 'api/select/competenze';
  public static LISTA_COMMESSE_COMBO_URL = 'api/select/commesse';
  public static LISTA_FATTURE_COMBO_URL = 'api/select/fatture';
  public static LISTA_COMMESSE_DIPENDENTE_COMBO_URL =
    'api/select/commesse-dipendente';
  public static LISTA_STRUMENTI_COMBO_URL = 'api/select/strumenti';

  public static LOGIN_URL = 'noauth/login/login';
  public static MENU_UTENTE_URL = 'api/menu/menu-utente';

  public static DATI_SITUAZIONE_AZIENDALE_URL =
    'api/dashboard-administrator/dati-situazione-aziendale';
  public static GET_NUMERO_CLIENTI_FORNITORI_URL =
    'api/dashboard-administrator/get-numero-clienti-fornitori';
}
