import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AddUpdateCommand } from 'src/app/model/command/add-update-command';
import { EliminaCommand } from 'src/app/model/command/elimina-command';
import { GetCommand } from 'src/app/model/command/get-command';
import { GetDatiSituazioneAziendaleCommand } from 'src/app/model/command/get-dati-situazione-aziendale-command';
import { GetFatturatoBarChartCommand } from 'src/app/model/command/get-fatturato-bar-chart-command';
import { GetNumeroClientiFornitoriCommand } from 'src/app/model/command/get-numero-clienti-fornitori-command';
import { GetNumeroContrattiCommand } from 'src/app/model/command/get-numero-contratti-command';
import { GetNumeroFattScaduteCommand } from 'src/app/model/command/get-numero-fatt-scadute-command';
import { GetNumeroLavoratoriCommand } from 'src/app/model/command/get-numero-lavoratori-command';
import { GetStipendiDipendentiCommand } from 'src/app/model/command/get-stipendi-dipendenti-command';
import { SearchCommand } from 'src/app/model/command/search-command';
import { SearchCompetenzeDipendenteCommand } from 'src/app/model/command/search-competenze-dipendente-command';
import { SelectCommand } from 'src/app/model/command/select-command';
import { ListBarChart } from 'src/app/model/dto/list/list-bar-chart';
import { ListCompetenzeDipendente } from 'src/app/model/dto/list/list-competenze-dipendente';
import { ListSelect } from 'src/app/model/dto/list/list-select';
import { NumeroClientiFornitori } from 'src/app/model/dto/numero-clienti-fornitori';
import { NumeroContratti } from 'src/app/model/dto/numero-contratti';
import { NumeroFattureScad } from 'src/app/model/dto/numero-fatture-scad';
import { NumeroLavoratori } from 'src/app/model/dto/numero-lavoratori';
import { NumeroNotificheNonLette } from 'src/app/model/dto/numero-notifiche-non-lette';
import { ResocontoAziendale } from 'src/app/model/dto/resoconto-aziendale';
import { TotaleStipendi } from 'src/app/model/dto/totale-stipendi';
import { Utente } from 'src/app/model/dto/utente';
import { GoToModel } from 'src/app/model/go-to-model';
import { Constants } from '../constants';
import { GetNumeroNotificheNonLetteCommand } from './../../model/command/get-numero-notifiche-non-lette';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root',
})
export class SharedCallService {
  public eventEmitter = new EventEmitter<string>();

  constructor(private http: HttpClient, public router: Router, private encryptionService: EncryptionService) {}

  /**
   *
   * {string} url un api verso cui fare una chiamata
   * @returns un observable che contiene una lista di elementi utilizzabili in una combo
   */
  select(url: string) {
    const currentIdSocieta = JSON.parse(
      sessionStorage.getItem('currentIdSocieta')
    );
    const command = new SelectCommand();
    command.idSocieta = currentIdSocieta;
    command.deleted = false;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<ListSelect>(url, {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * @returns un observable che contiene una lista di commesse associate ad un dipendente specifico
   */
  selectCommesseDipendente() {
    const currentIdSocieta = JSON.parse(
      sessionStorage.getItem('currentIdSocieta')
    );
    const currentUser: Utente = JSON.parse(
      sessionStorage.getItem('currentUser')
    );
    const command = new SelectCommand();
    command.idDipendente = currentUser.dipendente.id;
    command.idSocieta = currentIdSocieta;
    command.deleted = false;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<ListSelect>(
      Constants.LISTA_COMMESSE_DIPENDENTE_COMBO_URL,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * @returns un observable che contiene una lista di società
   */
  selectSocieta() {
    const command = new SelectCommand();
    command.deleted = false;
    // command.userId = this.getCurrentUser().id;
    // command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<ListSelect>(
      Constants.LISTA_SOCIETA_COMBO_URL,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * @returns un observable che contiene una lista di ruoli
   */
  selectRuoli() {
    const command = new SelectCommand();
    command.deleted = false;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<ListSelect>(Constants.LISTA_RUOLI_COMBO_URL, {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * @returns un observable che contiene una lista di tipi di ruoli
   */
  selectTipoRuolo() {
    const command = new SelectCommand();
    command.deleted = false;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<ListSelect>(
      Constants.LISTA_TIPO_RUOLO_COMBO_URL,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {boolean} tipologia specifica il tipo di fattura dove 'true' = fattura ricevuta e
   * 'false' = fattura emessa
   * @returns un observable che contiene una lista di fatture
   */
  selectFatture(tipologia: boolean) {
    const currentIdSocieta = JSON.parse(
      sessionStorage.getItem('currentIdSocieta')
    );
    const command = new SelectCommand();
    command.tipologia = tipologia;
    command.idSocieta = currentIdSocieta;
    command.deleted = false;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<ListSelect>(
      Constants.LISTA_FATTURE_COMBO_URL,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {string} competenza indica il nome della competenza
   * {string} dipendente indica il cognome del dipendente
   * {number} idDipendente l'identificativo numerico del dipendente
   * {boolean} isPaginata indica se la richiesta deve essere paginata o no
   * @returns ritorna un observable contenente una lista delle competenze di un dipendente specifico
   */
  searchCompetenzeDipendente(
    competenza: string,
    dipendente: string,
    idDipendente: number,
    isPaginata: boolean
  ) {
    const command = new SearchCompetenzeDipendenteCommand();
    command.nomeCompetenza = !!competenza ? competenza : null;
    command.cognomeDipendente = !!dipendente ? dipendente : null;
    command.idDipendente = !!idDipendente ? idDipendente : null;
    if (isPaginata) {
      command.skip = 0;
      command.take = 10;
    }
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<ListCompetenzeDipendente>(
      Constants.SEARCH_COMPETENZE_DIPENDENTE,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {number} idSocieta l'identificativo numerico della società
   * {number} idClienteOrFornitore l'identificativo numerico del cliente o fornitore
   * {boolean} tipologia indica il tipo di contratto; 'false' = cliente, 'true' = fornitore
   * @returns ritorna un observable contenente il numero di contratti totali ed il numero di contratti attivi
   */
  loadNumeroContratti(
    idSocieta: number,
    idClienteOrFornitore: number,
    tipologia: boolean
  ) {
    const command = new GetNumeroContrattiCommand();
    command.idSocieta = idSocieta;
    command.id = idClienteOrFornitore;
    command.tipologia = tipologia;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<NumeroContratti>(
      Constants.GET_NUMERO_CONTRATTI_URL,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {number} idSocieta l'identificativo numerico della società
   * @returns ritorna un observable contenente il numero di dipendenti ed il numero di consulenti
   */
  loadNumeroLavoratori(idSocieta: number) {
    const command = new GetNumeroLavoratoriCommand();
    command.idSocieta = idSocieta;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<NumeroLavoratori>(
      Constants.GET_NUMERO_LAVORATORI_URL,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {number} idDipendente l'identificativo numerico del dipendente
   * @returns ritorna un observable contenente il totale degli
   * stipendi percepiti dal dipendenteed il totale relativo all'anno in corso
   */
  loadStipendi(idDipendente: number) {
    const command = new GetStipendiDipendentiCommand();
    command.id = idDipendente;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<TotaleStipendi>(
      Constants.GET_TOTALE_STIPENDI_DIPENDENTE_URL,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {number} idSocieta l'identificativo numerico della società
   * @returns ritorna un observable contenente il numero di clienti ed il numero di fornitori
   */
  loadNumeroClientiFornitori(idSocieta: number) {
    const command = new GetNumeroClientiFornitoriCommand();
    command.idSocieta = idSocieta;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<NumeroClientiFornitori>(
      Constants.GET_NUMERO_CLIENTI_FORNITORI_URL,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {number} idSocieta l'identificativo numerico della società
   * {boolean} tipologia specifica il tipo di fattura dove 'true' = fattura ricevuta e
   * 'false' = fattura emessa
   * @returns ritorna un observable contenente il numero e l'importo
   * delle fatture scadute e delle fatture in scadenza
   */
  loadNumeroFattScadute(idSocieta: number, tipologia: boolean) {
    const command = new GetNumeroFattScaduteCommand();
    command.idSocieta = idSocieta;
    command.tipologia = tipologia;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<NumeroFattureScad>(
      Constants.GET_NUMERO_FATTURE_SCADUTE_URL,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {number} currentIdSocieta l'identificativo numerico della società
   * {number} year indicazione dell'anno in cui effettuare la ricerca
   * @returns un observable contenente i dati relativi al fatturato aziendale
   */
  loadDatiSituazioneAziendale(currentIdSocieta: number, year: number) {
    const command = new GetDatiSituazioneAziendaleCommand();
    command.idSocieta = currentIdSocieta;
    command.year = year;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<ResocontoAziendale>(
      Constants.DATI_SITUAZIONE_AZIENDALE_URL,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {number} idUtenteLettura identificativo numerico dell'utente a cui è indirizzata la notifica
   * {boolean} letto filtro di ricerca delle notifiche; 'true' = mostra solo notifiche già lette, 'false' mostra solo notifiche da leggere
   * @returns ritorna un observable contenente il numero di notifiche lette o non lette dal dipendente
   */
  loadNotificheNonLette(idUtenteLettura: number, letto: boolean) {
    const command = new GetNumeroNotificheNonLetteCommand();
    command.idUtenteLettura = idUtenteLettura;
    command.letto = letto;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<NumeroNotificheNonLette>(
      Constants.GET_NOTIFICHE_NON_LETTE,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {number} currentIdSocieta l'identificativo numerico della società
   * {boolean} tipologia specifica il tipo di fattura dove 'true' = fattura ricevuta e
   * 'false' = fattura emessa
   * {number} yearOne indica il primo anno da prendere in considerazione
   * {number} yearTwo indica il primo anno da prendere in considerazione
   * @returns ritorna un observable contenente il fatturato dei 2 anni di riferimento distribuiti mensilmente
   */
  loadBarChartFatturatoEmessoAnnualeCompare(
    currentIdSocieta: number,
    tipologia: boolean,
    yearOne: number,
    yearTwo: number
  ) {
    const command = new GetFatturatoBarChartCommand();
    command.yearOne = yearOne;
    command.yearTwo = yearTwo;
    command.tipologia = tipologia;
    command.idSocieta = currentIdSocieta;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<ListBarChart>(
      Constants.COMPARE_FATTURATO_ANNUALE_URL,
      {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {number} id un identificativo numerico dell'oggetto da ricercare
   * {string} getUrl una api verso cui fare una chiamata
   * @returns ritorna un observable contenente dati dell'oggetto corrispondente all'id
   */
  get(id: number, getUrl: string) {
    const command = new GetCommand();
    command.id = id;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<any>(getUrl, {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {number} id un identificativo numerico dell'oggetto da ricercare
   * {boolean} deleted boolean che modifica il valore "deleted" dell'oggetto ricercato
   * {string} getUrl una api verso cui fare una chiamata
   * @returns ritorna un observable contenente la risposta generata dalla api
   */
  delete(id: number, deleted: boolean, getUrl: string) {
    const currentUser: Utente = JSON.parse(
      sessionStorage.getItem('currentUser')
    );
    const command = new EliminaCommand();
    command.id = id;
    command.deleted = deleted;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    command.currentIdDipendente = currentUser.dipendente.id;
    return this.http.post<any>(getUrl, {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {SearchCommand} search un oggetto contenente i criteri della ricerca
   * {string} searchUrl una api verso cui effettuare una chiamata
   * @returns un observable contenente una lista di elementi corrispondenti ai criteri di ricerca
   */
  search(command: SearchCommand, searchUrl: string) {
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<any>(searchUrl, {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * {AddUpdateCommand} command un oggetto contenente i dettagli dell'elemento da creare o modificare
   * {string} urlToCall una api verso cui effettuare una chiamata
   * @returns un observable contenente la risposta generata dalla api
   */
  createOrUpdate(command: AddUpdateCommand, urlToCall: string) {
    const currentIdSocieta = JSON.parse(
      sessionStorage.getItem('currentIdSocieta')
    );
    command.idSocieta = currentIdSocieta;
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<AddUpdateCommand>(urlToCall, {value:this.encryptionService.encrypt(command)});
  }

  /**
   *
   * goToModel
   */
  goTo(goToModel: GoToModel) {
    this.router.navigate([goToModel.url], {
      queryParams: {
        goBack: JSON.stringify(goToModel),
        date: new Date().getTime(),
      },
    });
  }

  /**
   *
   * {SearchCommand} search un oggetto contenente i criteri di ricerca
   * {string} url una api verso cui fare una chiamata
   * @returns un observable da cui è possibile scaricare un file excel
   */
  generateExcel(command: SearchCommand, url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({}),
    };
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<any>(url, {value:this.encryptionService.encrypt(command)}, httpOptions);
  }

  /**
   *
   * {GetCommand} command un oggetto contenente i dettagli del file da scaricare
   * {string} url una api verso cui effettuare una chiamata
   * @returns un observable da cui è possibile scaricare un file
   */
  download(command: GetCommand, url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({}),
    };
    command.userId = this.getCurrentUser().id;
    command.userRoleId = this.getCurrentUser().ruolo.id;
    return this.http.post<any>(url, {value:this.encryptionService.encrypt(command)}, httpOptions);
  }

  /**
   *
   * date una strina o un oggetto che rappresenta un istante nel tempo
   * @returns un oggetto di tipo Date a cui è stato modificato l'orario per rispecchiare l'orario in cui è localizzato l'applicativo
   */
  normalizeDateTime(date: any) {
    const selectedDate = new Date(date);
    return new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    );
  }

  getCurrentUser() : Utente {
    return JSON.parse(
      sessionStorage.getItem('currentUser')
    );
  }
}
