import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService, LayoutStore } from 'angular-admin-lte';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import defaultLanguage from './../assets/i18n/it.json';
import { SharedService } from './shared/shared-services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
  public customLayout: boolean;
  currentYear: string;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    private translate: TranslateService,
    private layoutStore: LayoutStore,
    private layoutService: LayoutService,
    private idle: Idle,
    public router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    private keepalive: Keepalive,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedService
  ) {
    this.currentYear = new Date().getFullYear().toString();

    translate.setTranslation('it', defaultLanguage);
    translate.setDefaultLang('it');

    idle.setIdle(900);
    idle.setTimeout(300);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => (this.idleState = 'No longer idle.'));
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;

      // MOSTRO POPUP SESSIONE SCADUTA E MANDO ALLA LOGIN
      const text = 'Sessione Scaduta, è necessario effettuare il login!';
      this.sharedService.showWarningAlert(text);
      this.ngxSmartModalService
        .getModal('warningModal')
        .onClose.subscribe((modal: NgxSmartModalComponent) => {
          sessionStorage.clear();
          localStorage.clear();
          this.router.navigate(['login']);
        });
    });
    // tslint:disable-next-line
    idle.onIdleStart.subscribe(() => (this.idleState = "You've gone idle!"));
    idle.onTimeoutWarning.subscribe(
      (countdown) =>
        (this.idleState = 'You will time out in ' + countdown + ' seconds!')
    );

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  ngOnInit() {
    const currentMenu = sessionStorage.getItem('currentMenu');
    if (currentMenu) {
      this.layoutStore.setSidebarLeftMenu(JSON.parse(currentMenu));
    }
    this.layoutService.isCustomLayout.subscribe((value: boolean) => {
      this.customLayout = value;
    });
    // this.bnIdle.startWatching(60).subscribe((isTimedOut: boolean) => {
    //   if (isTimedOut) {
    //     console.log('session expired');
    //   }
    // });

    // this.spinner.show();

    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spinner.hide();
    // }, 5000);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
