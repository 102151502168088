import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
// tslint:disable-next-line: max-line-length
import {
  AccordionModule as MkAccordionModule,
  AlertModule as MkAlertModule,
  BoxInfoModule as MkBoxInfoModule,
  BoxModule,
} from 'angular-admin-lte';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { ChartsModule } from 'ng2-charts';
import { A2Edatetimepicker } from 'ng2-eonasdan-datetimepicker';
import { BarChartComponent } from '../../components/bar-chart/bar-chart.component';
import { DoughnutChartComponent } from '../../components/doughnut-chart/doughnut-chart.component';
import { InfoBoxComponent } from '../../components/info-box/info-box.component';
import { IvaBoxComponent } from '../../components/iva-box/iva-box.component';
import { PaginatoreModule } from '../../components/paginatore/paginatore.module';
import { PxltkFormModule } from '../../components/pxltk-form/pxltk-form.module';
import { PxltkHeaderModule } from '../../components/pxltk-header/pxltk-header.module';
import { PxltkSearchModule } from '../../components/pxltk-search/pxltk-search.module';
import { PxltkTableModule } from '../../components/pxltk-table/pxltk-table.module';
import { SmallBoxComponent } from '../../components/small-box/small-box.component';
import { StarRatingModule } from '../../components/star-rating/star-rating.module';
import { TabellaComponent } from '../../components/tabella/tabella.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MkAccordionModule,
    ReactiveFormsModule,
    MatFileUploadModule,
    A2Edatetimepicker,
    MkAlertModule,
    BoxModule,
    PaginatoreModule,
    PxltkFormModule,
    PxltkTableModule,
    PxltkHeaderModule,
    PxltkSearchModule,
    MkBoxInfoModule,
    ChartsModule,
    StarRatingModule,
  ],
  declarations: [
    TabellaComponent,
    SmallBoxComponent,
    DoughnutChartComponent,
    BarChartComponent,
    InfoBoxComponent,
    IvaBoxComponent,
  ],
  exports: [
    PaginatoreModule,
    PxltkFormModule,
    PxltkTableModule,
    PxltkHeaderModule,
    PxltkSearchModule,
    FormsModule,
    ReactiveFormsModule,
    MatFileUploadModule,
    MkAlertModule,
    BoxModule,
    TabellaComponent,
    MkBoxInfoModule,
    A2Edatetimepicker,
    TranslateModule,
    MkAccordionModule,
    ChartsModule,
    SmallBoxComponent,
    DoughnutChartComponent,
    InfoBoxComponent,
    IvaBoxComponent,
    StarRatingModule,
    BarChartComponent,
  ],
})
export class SharedComponentsModule {}
