import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginatore',
  templateUrl: './paginatore.component.html',
  styleUrls: ['./paginatore.component.scss'],
})
export class PaginatoreComponent {
  @Input() idVaiA: any;
  @Input() vaiAList: any;
  @Input() count: any;
  @Input() skip: any;
  @Input() offset: any;
  @Input() take: any;
  @Input() currentPage: any;
  @Input() pages: any;
  @Input() havePreviousPage: any;
  @Input() haveNextPage: any;
  @Output() selectionChangeEmitter = new EventEmitter();
  @Output() previousPageEmitter = new EventEmitter();
  @Output() nextPageEmitter = new EventEmitter();

  goToPage(idVaiA: string, event: any) {
    this.selectionChangeEmitter.emit({
      idVaiA,
      take: this.take,
      offset: this.offset,
    });
  }

  previousPage(skip: any, take: any, offset: any) {
    this.previousPageEmitter.emit({ skip, take, offset });
  }

  nextPage(skip: any, take: any, count: any, offset: any) {
    this.nextPageEmitter.emit({
      skip,
      take,
      count,
      offset,
    });
  }
}
