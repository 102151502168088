import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  secretKey = environment.AES_SECRET_KEY;

  constructor() {}

  encrypt(value: any): string {
    return btoa(JSON.stringify(value));
  }

  decrypt(resp: any) {
    return JSON.parse(atob(resp.result));
  }
}
