// tslint:disable-next-line
import {
  // tslint:disable-next-line
  HttpClient,
  // tslint:disable-next-line
  HttpClientModule,
  // tslint:disable-next-line
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BreadcrumbsModule, LayoutModule } from 'angular-admin-lte';
// COMPONENTS
import { LoadingPageModule, MaterialBarModule } from 'angular-loading-page';
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { adminLteConf } from './admin-lte.conf';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { RoleGuardService } from './auth/role-guard.service';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { KoModalComponent } from './components/ko-modal/ko-modal.component';
// tslint:disable-next-line: max-line-length
import { OkModalComponent } from './components/ok-modal/ok-modal.component';
import { PxltkFileUploadModule } from './components/pxltk-file-upload/pxltk-file-upload.module';
import { WarningModalComponent } from './components/warning-modal/warning-modal.component';
import { CoreModule } from './core/core.module';
import { InterceptorsService } from './interceptor/interceptors.interceptor';
// PAGES
// import { HomeModule } from './pages/student-page/home/home.module';
import { DashboardAdministratorModule } from './pages/administrator-page/dashboard-administrator/dashboard-administrator.module';
import { DashboardDipendenteModule } from './pages/dipendente-page/dashboard-dipendente/dashboard-dipendente.module';

// tslint:disable-next-line: variable-name
const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter() {
      return 'TODO';
    },
  },
};
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    AppRoutingModule,
    CoreModule,
    JwtModule.forRoot(JWT_Module_Options),
    LayoutModule.forRoot(adminLteConf),
    LoadingPageModule,
    MaterialBarModule,
    NgxSmartModalModule.forRoot(),
    NgxSpinnerModule,
    BreadcrumbsModule,

    // ADMINISTRATOR
    DashboardAdministratorModule,

    // DIPENDENTE
    DashboardDipendenteModule,
    PxltkFileUploadModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService,
      multi: true,
    },
    // {
    //   provide: APP_BASE_HREF,
    //   useValue: '/cla-angular'
    // },
    // BnNgIdleService,
    NgxSmartModalService,
    AuthService,
    RoleGuardService,
    AuthGuardService,
  ],
  declarations: [
    AppComponent,
    OkModalComponent,
    KoModalComponent,
    DeleteModalComponent,
    WarningModalComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
