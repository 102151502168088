import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// componente che crea un diagramma a torta, le etichette vengono scritte in 'labels' e i dati in 'data'

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
  // tslint:disable-next-line
  host: {
    '(window:resize)': 'onWindowResize($event)',
  },
})
export class DoughnutChartComponent {
  @Input() title: string;
  @Input() link: string;
  @Input() labels: string[];
  @Input() data: [];
  @Input() type = 'doughnut';

  public donutColors = [
    {
      backgroundColor: ['#45d75c', '#fd9189', '#a37cd7'],
    },
  ];
  public legend = window.innerWidth > 400 ? true : false;

  constructor(public router: Router) {}

  openLink() {
    this.router.navigate([this.link]);
  }
  onWindowResize(event) {
    if (event.target.innerWidth < 400) {
      this.legend = false;
    } else {
      this.legend = true;
    }
  }
}
