import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatSnackBar } from '@angular/material';
/**
 * componente che crea un sistema di valutazione da 1 a 5 stelle.
 * Il componente viene utilizzato da pxltk-form-component durante la creazione dei form che ne richiedono l'utilizzo
 */
@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class StarRatingComponent implements OnInit {
  @Input() public rating = 3;
  @Input() public starCount = 5;
  @Input() public color = 'accent';
  @Output() public ratingUpdated = new EventEmitter();

  private snackBarDuration = 2000;
  public ratingArr = [];

  constructor(private snackBar: MatSnackBar) {}

  ngOnInit() {
    console.log('a ' + this.starCount);
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }

  onClick(rating: number) {
    console.log(rating);
    this.snackBar.open('Livello ' + rating + ' / ' + this.starCount, '', {
      duration: this.snackBarDuration,
    });
    this.ratingUpdated.emit(rating);
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
}
export enum StarRatingColor {
  primary = 'primary',
  accent = 'accent',
  warn = 'warn',
}
