import { Select } from '../model/dto/select';
import { Utente } from '../model/dto/utente';
import { PxltkComboName } from '../model/enum/pxltk-combo-name';
import { PxltkFormType } from '../model/enum/pxltk-form-type';
import { Constants } from '../shared/constants';
import { EncryptionService } from '../shared/shared-services/encryption.service';
import { SharedCallService } from '../shared/shared-services/shared-call.service';

export abstract class PxltkFormSearchBaseComponent {
  typeField = PxltkFormType;
  combo: [Select[]] = [[]];

  DATE_MONTH_YEAR = {
    format: 'MM/YYYY',
    locale: 'it',
  };

  DATE_YEAR = {
    format: 'YYYY',
    locale: 'it',
  };

  DATE = {
    format: 'DD/MM/YYYY',
    locale: 'it',
  };

  DATELIMITED = {
    format: 'DD/MM/YYYY',
    locale: 'it',
    minDate: this.minDateCalculator(),
  };

  DATE_TIME = {
    format: 'DD/MM/YYYY HH:mm',
    locale: 'it',
  };

  minDateCalculator() {
    const today = new Date();
    const minDate = new Date();
    minDate.setDate(1);
    minDate.setHours(0, 0, 0);
    minDate.setMilliseconds(0);
    if (today.getDate() >= 6) {
      return minDate;
    } else {
      if (today.getMonth() === 0) {
        minDate.setMonth(11);
        minDate.setFullYear(today.getFullYear() - 1);
      } else {
        minDate.setMonth(today.getMonth() - 1);
      }
      return minDate;
    }
  }

  constructor(
    public sharedCallService: SharedCallService,
    public encryptionService: EncryptionService
  ) {}

  loadSocieta() {
    this.sharedCallService.selectSocieta().subscribe(
      (result) => {
        result = this.encryptionService.decrypt(result);
        this.combo[PxltkComboName.SOCIETA] = result.list;
      },
      (error) => {
        console.log('Errore selectSocieta');
      }
    );
  }

  loadTipoRuolo() {
    this.sharedCallService.selectTipoRuolo().subscribe(
      (result) => {
        result = this.encryptionService.decrypt(result);
        this.combo[PxltkComboName.TIPO_RUOLO] = result.list;
      },
      (error) => {
        console.log('Errore selectTipoRuolo');
      }
    );
  }

  loadClienti() {
    this.sharedCallService.select(Constants.LISTA_CLIENTI_COMBO_URL).subscribe(
      (result) => {
        result = this.encryptionService.decrypt(result);
        this.combo[PxltkComboName.CLIENTE] = result.list;
      },
      (error) => {
        console.log('Errore selectClienti');
      }
    );
  }

  loadContratti() {
    this.sharedCallService
      .select(Constants.LISTA_CONTRATTI_COMBO_URL)
      .subscribe(
        (result) => {
          result = this.encryptionService.decrypt(result);
          this.combo[PxltkComboName.CONTRATTO] = result.list;
        },
        (error) => {
          console.log('Errore selectContratti');
        }
      );
  }

  loadTipoFatture() {
    this.sharedCallService
      .select(Constants.LISTA_TIPO_FATTURE_COMBO_URL)
      .subscribe(
        (result) => {
          result = this.encryptionService.decrypt(result);
          this.combo[PxltkComboName.TIPO_FATTURA] = result.list;
        },
        (error) => {
          console.log('Errore selectTipoFatture');
        }
      );
  }

  loadTipoPagamenti() {
    this.sharedCallService
      .select(Constants.LISTA_TIPO_PAGAMENTI_COMBO_URL)
      .subscribe(
        (result) => {
          result = this.encryptionService.decrypt(result);
          this.combo[PxltkComboName.TIPO_PAGAMENTO] = result.list;
        },
        (error) => {
          console.log('Errore selectTipoPagamenti');
        }
      );
  }

  loadDipendenti() {
    this.sharedCallService
      .select(Constants.LISTA_DIPENDENTI_COMBO_URL)
      .subscribe(
        (result) => {
          result = this.encryptionService.decrypt(result);
          this.combo[PxltkComboName.DIPENDENTE] = result.list;
        },
        (error) => {
          console.log('Errore selectDipendenti');
        }
      );
  }

  loadDipendentiClone() {
    this.sharedCallService
      .select(Constants.LISTA_DIPENDENTI_COMBO_URL)
      .subscribe(
        (result) => {
          result = this.encryptionService.decrypt(result);
          this.combo[PxltkComboName.DIPENDENTE_CLONE] = result.list;
        },
        (error) => {
          console.log('Errore selectDipendenti');
        }
      );
  }

  loadCommessa() {
    this.sharedCallService.select(Constants.LISTA_COMMESSE_COMBO_URL).subscribe(
      (result) => {
        result = this.encryptionService.decrypt(result);
        this.combo[PxltkComboName.COMMESSA] = result.list;
      },
      (error) => {
        console.log('Errore selectCommesse');
      }
    );
  }

  loadCommesseDipendente() {
    this.sharedCallService.selectCommesseDipendente().subscribe(
      (result) => {
        result = this.encryptionService.decrypt(result);
        this.combo[PxltkComboName.COMMESSE_DIPENDENTE] = result.list;
      },
      (error) => {
        console.log('Errore selectCommesseDipendente');
      }
    );
  }

  loadRuoli() {
    this.sharedCallService.selectRuoli().subscribe(
      (result) => {
        result = this.encryptionService.decrypt(result);
        const currentUser: Utente = JSON.parse(
          sessionStorage.getItem('currentUser')
        );
        const ruoloUser = currentUser.ruolo.nome;
        this.combo[PxltkComboName.RUOLO] =
          ruoloUser === 'SUPERUSER'
            ? result.list
            : result.list.filter((el) => el.viewValue !== 'SUPERUSER');
      },
      (error) => {
        console.log('Errore selectRuoli');
      }
    );
  }

  loadRuoliProfessionali() {
    this.sharedCallService
      .select(Constants.LISTA_RUOLI_PROFESSIONALI_COMBO_URL)
      .subscribe(
        (result) => {
          result = this.encryptionService.decrypt(result);
          this.combo[PxltkComboName.RUOLO_PROFESSIONALE] = result.list;
        },
        (error) => {
          console.log('Errore selectRuoliProfessionali');
        }
      );
  }

  loadFornitori() {
    this.sharedCallService
      .select(Constants.LISTA_FORNITORI_COMBO_URL)
      .subscribe(
        (result) => {
          result = this.encryptionService.decrypt(result);
          this.combo[PxltkComboName.FORNITORE] = result.list;
        },
        (error) => {
          console.log('Errore selectFornitori');
        }
      );
  }

  loadCompetenze() {
    this.sharedCallService
      .select(Constants.LISTA_COMPETENZE_COMBO_URL)
      .subscribe(
        (result) => {
          result = this.encryptionService.decrypt(result);
          this.combo[PxltkComboName.COMPETENZA] = result.list;
        },
        (error) => {
          console.log('Errore selectCompetenze');
        }
      );
  }

  loadFattureRicevute() {
    this.sharedCallService.selectFatture(true).subscribe(
      (result) => {
        result = this.encryptionService.decrypt(result);
        this.combo[PxltkComboName.FATTURE_RICEVUTE] = result.list;
      },
      (error) => {
        console.log('Errore selectFatture');
      }
    );
  }

  loadFattureEmesse() {
    this.sharedCallService.selectFatture(false).subscribe(
      (result) => {
        result = this.encryptionService.decrypt(result);
        this.combo[PxltkComboName.FATTURE_EMESSE] = result.list;
      },
      (error) => {
        console.log('Errore selectFatture');
      }
    );
  }

  loadStrumenti() {
    this.sharedCallService
      .select(Constants.LISTA_STRUMENTI_COMBO_URL)
      .subscribe(
        (result) => {
          result = this.encryptionService.decrypt(result);
          this.combo[PxltkComboName.STRUMENTO] = result.list;
        },
        (error) => {
          console.log('Errore selectStrumenti');
        }
      );
  }

  loadTipoCliente() {
    const tipiCliente = [];
    const tipoPrivato = new Select();
    tipoPrivato.value = '0';
    tipoPrivato.viewValue = 'PRIVATO';
    const tipoSocieta = new Select();
    tipoSocieta.value = '1';
    tipoSocieta.viewValue = 'SOCIETA';
    const tipoPubblicaAmministrazione = new Select();
    tipoPubblicaAmministrazione.value = '2';
    tipoPubblicaAmministrazione.viewValue = 'PUBBLICA AMMINISTRAZIONE';
    tipiCliente.push(tipoPrivato);
    tipiCliente.push(tipoSocieta);
    tipiCliente.push(tipoPubblicaAmministrazione);
    this.combo[PxltkComboName.TIPO_CLIENTE] = tipiCliente;
  }

  loadSiNo() {
    const list = [];
    const si = new Select();
    si.value = 'true';
    si.viewValue = 'SI';
    const no = new Select();
    no.value = 'false';
    no.viewValue = 'NO';
    list.push(si);
    list.push(no);
    this.combo[PxltkComboName.SI_NO] = list;
  }

  loadSiNoAll() {
    const list = [];
    const all = new Select();
    all.value = 'null';
    all.viewValue = 'TUTTI';
    const si = new Select();
    si.value = 'true';
    si.viewValue = 'SI';
    const no = new Select();
    no.value = 'false';
    no.viewValue = 'NO';
    list.push(all);
    list.push(si);
    list.push(no);
    this.combo[PxltkComboName.SI_NO_ALL] = list;
  }

  protected buildCombo(comboName: PxltkComboName) {
    switch (comboName) {
      case PxltkComboName.SOCIETA:
        this.loadSocieta();
        break;
      case PxltkComboName.CLIENTE:
        this.loadClienti();
        break;
      case PxltkComboName.CONTRATTO:
        this.loadContratti();
        break;
      case PxltkComboName.TIPO_FATTURA:
        this.loadTipoFatture();
        break;
      case PxltkComboName.TIPO_PAGAMENTO:
        this.loadTipoPagamenti();
        break;
      case PxltkComboName.DIPENDENTE:
        this.loadDipendenti();
        break;
      case PxltkComboName.DIPENDENTE_CLONE:
        this.loadDipendentiClone();
        break;
      case PxltkComboName.COMMESSA:
        this.loadCommessa();
        break;
      case PxltkComboName.COMMESSE_DIPENDENTE:
        this.loadCommesseDipendente();
        break;
      case PxltkComboName.RUOLO:
        this.loadRuoli();
        break;
      case PxltkComboName.RUOLO_PROFESSIONALE:
        this.loadRuoliProfessionali();
        break;
      case PxltkComboName.FORNITORE:
        this.loadFornitori();
        break;
      case PxltkComboName.COMPETENZA:
        this.loadCompetenze();
        break;
      case PxltkComboName.TIPO_CLIENTE:
        this.loadTipoCliente();
        break;
      case PxltkComboName.TIPO_RUOLO:
        this.loadTipoRuolo();
        break;
      case PxltkComboName.SI_NO:
        this.loadSiNo();
        break;
      case PxltkComboName.SI_NO_ALL:
        this.loadSiNoAll();
        break;
      case PxltkComboName.FATTURE_EMESSE:
        this.loadFattureEmesse();
        break;
      case PxltkComboName.FATTURE_RICEVUTE:
        this.loadFattureRicevute();
        break;
      case PxltkComboName.STRUMENTO:
        this.loadStrumenti();
        break;
    }
  }
}
