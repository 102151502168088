import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/shared/shared-modules/material.module';
import { SharedComponentsModule } from '../../../shared/shared-modules/shared-component.module';
import { DashboardAdministratorRoutingModule } from './dashboard-administrator-routing.module';
import { DashboardAdministratorComponent } from './dashboard-administrator.component';
import { ConfettinatorModule } from '../../../components/confettinator/confettinator.module';

@NgModule({
  imports: [
    CommonModule,
    DashboardAdministratorRoutingModule,
    SharedComponentsModule,
    MaterialModule,
    ConfettinatorModule
  ],
  declarations: [DashboardAdministratorComponent],
})
export class DashboardAdministratorModule {}
