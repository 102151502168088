import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Column } from 'src/app/model/dto/column';
import { Generic } from 'src/app/model/dto/generic';
import { Header } from 'src/app/model/dto/header';
import { PageableList } from 'src/app/model/dto/pageable-list';
import { PxltkFormType } from 'src/app/model/enum/pxltk-form-type';
import { PxltkSearchModel } from 'src/app/model/pxltk-search-model';
import { SharedService } from './../../shared/shared-services/shared.service';

// component che crea una tabella sulla base degli input ricevuti
@Component({
  selector: 'app-pxltk-table',
  templateUrl: './pxltk-table.component.html',
  styleUrls: ['./pxltk-table.component.scss'],
  animations: [
    trigger('additionalInfo', [
      state(
        'initial',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: '0',
          visibility: 'hidden',
          // display: 'none'
        })
      ),
      state(
        'final',
        style({
          overflow: 'hidden',
          // display: 'table-row'
        })
      ),
      transition('initial<=>final', animate('250ms')),
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(180deg)' })),
      transition('default <=> rotated', animate('250ms')),
    ]),
  ],
})
export class PxltkTableComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() showActions = false;
  @Input() showList = false;
  @Input() urlEdit: string;
  @Input() paginatore: PageableList;
  @Input() getUrl: string;
  @Input() deleteUrl: string;
  @Input() deleteFileUrl: string;
  @Input() hideModifyButton = false;
  @Input() hideDeleteButton = false;
  @Input() showDetailButton = false;
  @Input() showUploadButton = false;
  @Input() showDownloadButton = false;
  @Input() showDeleteButton = false;
  @Input() detailUrl;
  @Input() showCustomButton = false;
  @Input() showExcel = true;
  @Input() customIconButton = '';
  @Input() customTitle = '';
  @Input() showResetOrder = true;
  @Input() showOptionalButton: boolean;
  @Input() searchModel: PxltkSearchModel[];
  @Input() pathBack: string;

  deleteModalSubscribe: Subscription;
  _headers: Header[];
  _rows: Generic[];
  typeField = PxltkFormType;
  show: number;
  public optionalButtonMethodName: string;
  table: Column[][];

  get headers(): Header[] {
    return this._headers;
  }

  // attraverso la lista di Header vengono costruite le colonne della tabella
  @Input()
  set headers(headers: Header[]) {
    if (headers) {
      this._headers = headers;
    }
  }

  get rows(): Generic[] {
    return this._rows;
  }

  @Input()
  set rows(rows: Generic[]) {
    if (rows) {
      this._rows = rows;
      this.table = [];
      for (const row of this.rows) {
        this.table.push(this.calculateArray(row));
      }
    }
  }

  @Output() goToPageEvent = new EventEmitter();
  @Output() previousPageEvent = new EventEmitter();
  @Output() nextEvent = new EventEmitter();
  @Output() generateExcelEvent = new EventEmitter();
  @Output() uploadEvent = new EventEmitter();
  @Output() downloadEvent = new EventEmitter();
  @Output() orderByEvent = new EventEmitter();
  @Output() customButtonEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() optionalButtonEvent = new EventEmitter();

  calculateArray(row: any) {
    const columns: Column[] = [];
    this.headers.forEach((header) => {
      const currColumnType = !!header.columnType
        ? header.columnType
        : PxltkFormType.TEXT;
      const currLabel = header.viewValue;
      const currShowInMob = header.showInMobile;
      if (header.isFieldComposed) {
        const splitCode = header.codice.split(';');
        const unionField = [];
        splitCode.forEach((code) => {
          const splitField = code.split('.');
          if (splitField.length > 1) {
            const code2 = splitField[0];
            const subCode = splitField[1];
            if (!!row[code2][subCode]) {
              unionField.push(row[code2][subCode]);
            }
          } else if (
            !!row[header.codice] ||
            row[header.codice] === null ||
            row[header.codice] === 0 ||
            row[header.codice] === false
          ) {
            unionField.push(row[header.codice]);
          }
        });
        const column = new Column();
        column.showInMobile = currShowInMob;
        column.type = currColumnType;
        column.label = currLabel;
        column.viewValue = unionField.join(' ');
        columns.push(column);
      } else {
        const splitCode = header.codice.split('.');
        const column = new Column();
        column.showInMobile = currShowInMob;
        column.label = currLabel;
        if (splitCode.length > 1) {
          const code1 = splitCode[0];
          const subCode = splitCode[1];
          if (!!row[code1] && !!row[code1][subCode]) {
            column.type = currColumnType;
            if (header.levelField === 2) {
              column.viewValue = row[code1][subCode][splitCode[2]];
            } else {
              column.viewValue = row[code1][subCode];
            }
            columns.push(column);
          } else {
            // EMPTY
            column.viewValue = '';
            columns.push(column);
          }
          // tslint:disable-next-line: max-line-length
        } else if (
          !!row[header.codice] ||
          row[header.codice] === null ||
          row[header.codice] === 0 ||
          row[header.codice] === false ||
          row[header.codice] === ''
        ) {
          column.type = currColumnType;
          column.viewValue = row[header.codice];
          column.showInMobile = currShowInMob;
          column.label = currLabel;
          columns.push(column);
        }
      }
    });
    return columns;
  }

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService
  ) {}

  ngOnDestroy(): void {
    if (this.deleteModalSubscribe) {
      this.deleteModalSubscribe.unsubscribe();
    }
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.deleteModalSubscribe = this.sharedService.deleteEvent.subscribe(
      (result) => {
        if (result) {
          this.delete(result);
        }
      }
    );
  }

  orderBy(codice: string, type: string, skip: any, take: any, offset: any) {
    const obj = {
      codice,
      typeOrder: type,
      skip,
      take,
      offset,
    };
    this.headers.forEach((header) => {
      header.haveCssAsc = false;
      header.haveCssDesc = false;
      if (header.codice === codice) {
        type === 'ASC'
          ? (header.haveCssAsc = true)
          : (header.haveCssDesc = true);
      }
    });
    this.orderByEvent.emit(obj);
  }

  goToEditDetail(id: number) {
    this.sharedService.removeCurrFilter();
    this.sharedService.addCurrFilter(
      this.searchModel,
      this.paginatore,
      this.pathBack
    );
    this.router.navigate([this.urlEdit], { queryParams: { id } });
  }

  goToDetail(id: number) {
    this.spinner.show();
    this.sharedService.removeCurrFilter();
    this.sharedService.addCurrFilter(
      this.searchModel,
      this.paginatore,
      this.pathBack
    );
    this.router.navigate([this.detailUrl], { queryParams: { id } });
  }

  goToDelete(id: number, deleted: boolean) {
    const data = {
      idElement: id,
      deleteUrl: this.deleteUrl,
      deleted: !deleted,
    };
    this.ngxSmartModalService.getModal('deleteModal').removeData();
    this.ngxSmartModalService.setModalData(data, 'deleteModal');
    this.ngxSmartModalService.getModal('deleteModal').open();
  }

  delete(event: any) {
    this.sharedService.showOkAlert();
    this.deleteEvent.emit(event);
  }

  goToPage(event: any) {
    this.goToPageEvent.emit(event);
  }

  previousPage(event: any) {
    this.previousPageEvent.emit(event);
  }

  nextPage(event: any) {
    this.nextEvent.emit(event);
  }

  customFunction(event: any) {
    this.customButtonEvent.emit(event);
  }

  optionalFunction(event: any) {
    this.optionalButtonEvent.emit(event);
  }

  goToDeleteFile(id: number) {
    const data = {
      idElement: id,
      deleteUrl: this.deleteFileUrl,
      deleted: true,
    };
    this.ngxSmartModalService.getModal('deleteModal').removeData();
    this.ngxSmartModalService.setModalData(data, 'deleteModal');
    this.ngxSmartModalService.getModal('deleteModal').open();
  }

  generateExcel(event: any) {
    this.generateExcelEvent.emit(event);
  }

  download(event: any) {
    if (event.filePresent) {
      this.downloadEvent.emit(event);
    } else {
      const text = 'Non è presente nessun file caricato da poter scaricare!';
      this.sharedService.showWarningAlert(text);
    }
  }

  upload(event: any) {
    this.uploadEvent.emit(event);
  }

  showUnshow(index) {
    if (this.show === index) {
      this.show = null;
    } else {
      this.show = index;
    }
  }
  optionalButton(event: any) {
    this[this.optionalButtonMethodName](event);
  }
}
