import { HttpHeaders } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants';
import { SharedService } from '../../shared/shared-services/shared.service';

@Component({
  selector: 'app-pxltk-file-upload',
  templateUrl: './pxltk-file-upload.component.html',
  styleUrls: ['./pxltk-file-upload.component.scss'],
})
export class PxltkFileUploadComponent implements OnInit, OnDestroy {
  private modalSubscribe: Subscription;
  backEndUrl: string;
  httpHeaders: HttpHeaders;
  @Output() uploadedEmitter = new EventEmitter();

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private sharedService: SharedService
  ) {
    this.backEndUrl = Constants.URL_BACK_END;
    this.httpHeaders = new HttpHeaders().set(
      'Authorization',
      sessionStorage.getItem('token')
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (!!this.modalSubscribe) {
      this.modalSubscribe.unsubscribe();
    }
  }

  verifyFile(file: File, event: any, supportedFiles: string): void {
    this.ngxSmartModalService.getModal('fileUpload').close();
    if (file && file.name.indexOf(supportedFiles) <= 0) {
      this.sharedService.showKoAlert();
    } else {
      this.sharedService.showOkAlert();
      this.modalSubscribe = this.ngxSmartModalService
        .getModal('okModal')
        .onClose.subscribe((modal: NgxSmartModalComponent) => {
          this.uploadedEmitter.emit(true);
        });
    }
  }
}
