import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NumeroNotificheNonLette } from 'src/app/model/dto/numero-notifiche-non-lette';
import { AbstractBaseComponent } from 'src/app/model/parent/abstract-base-component';
import { SharedCallService } from 'src/app/shared/shared-services/shared-call.service';
import { EncryptionService } from '../../shared/shared-services/encryption.service';
@Component({
  selector: 'app-header-inner',
  templateUrl: './header-inner.component.html',
})
export class HeaderInnerComponent
  extends AbstractBaseComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  notificheNonLette: NumeroNotificheNonLette;

  ngOnInit(): void {
    super.ngOnInit();
    this.updateNumeroNotifiche();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  constructor(
    public encryptionService: EncryptionService,
    public router: Router,
    public sharedCallService: SharedCallService
  ) {
    super(sharedCallService, encryptionService);
    this.sharedCallService.eventEmitter.subscribe((result: string) => {
      if (result === 'my_notifiche') {
        this.updateNumeroNotifiche();
      }
    });
  }

  goToProfilo() {
    this.router.navigate(['/a-profilo/modifica']);
  }

  goToProfiloStudente() {
    this.router.navigate(['/modifica-profilo']);
  }

  /**
   *
   * @method void reindirizza alla pagina delle notifiche personali
   */
  goToNotifiche(e) {
    e.preventDefault();
    this.router.navigate(['/my-notifiche/list']);
  }

  /**
   * @method void consente di eseguire il logout
   */
  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  /**
   * @method void ricerca il numero di notifiche non lette dall'utente e le assegna alla variabile 'notificheNonLette'
   */
  updateNumeroNotifiche() {
    this.sharedCallService
      .loadNotificheNonLette(this.currentUser.dipendente.id, false)
      .subscribe((result: NumeroNotificheNonLette) => {
        result = this.encryptionService.decrypt(result);
        this.notificheNonLette = result;
      });
  }
}
