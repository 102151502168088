import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Constants } from '../shared/constants';
import { SharedService } from '../shared/shared-services/shared.service';

@Injectable()
export class InterceptorsService implements HttpInterceptor {
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const BASE_URL = Constants.URL_BACK_END;

    let newReq;
    console.log('intercepted request ... ');
    if (req.url.indexOf('api') === -1 && req.url.indexOf('noauth') === -1) {
      return next.handle(req);
    } else if (req.url.indexOf('api') === 1 || req.url.indexOf('api') === 0) {
      const newUrl = BASE_URL + req.url;
      newReq = req.clone({
        headers: new HttpHeaders({
          Authorization: sessionStorage.getItem('token'),
          'user-id': !!this.sharedService.getCurrentUser() ? ''+this.sharedService.getCurrentUser().id : '-1',
          'user-role-id': !!this.sharedService.getCurrentUser() ? ''+this.sharedService.getCurrentUser().ruolo.id : '-1',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Methods':
            'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        }),
        url: newUrl,
      });
    } else if (
      req.url.indexOf('noauth') === 1 ||
      req.url.indexOf('noauth') === 0
    ) {
      const newUrl = BASE_URL + req.url;
      newReq = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json; text/plain',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Methods':
            'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        }),
        url: newUrl,
      });
    }
    console.log('Sending request with new header now ...');
    return next
      .handle(newReq)
      .pipe(timeout(300000))
      .pipe(
        catchError((error, caught) => {
          // intercept the respons error and displace it to the console
          this.spinner.hide();
          console.log(error);
          this.handleAuthError(error);
          return of(error);
        }) as any
      );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 0) {
      console.log('handled error ' + err.status);
      return of(err.message);
    }
    if (err.status === 404) {
      console.log('errore 404 ' + err.status);
      return of(err.message);
    }
    if (err.status === 500) {
      console.log('errore 500 ' + err.message);
      const text = err.error.message;
      this.sharedService.showWarningAlert(text);
      return of(err.message);
    }
    throw err;
  }
}
