import { AddUpdateCommand } from './add-update-command';
export class AddUpdateDipendenteCommand extends AddUpdateCommand {
  public matricola: string;
  public nome: string;
  public cognome: string;
  public codiceFiscale: string;
  public cellulare: string;
  public email: string;
  public cartaIdentita: string;
  public fileCartaIdentita: any;
  public fileCodiceFiscale: any;
  public dataNascita: Date;
  public luogoNascita: string;
  public indirizzoResidenza: string;
  public indirizzoDomicilio: string;
  public coordinateIban: string;
  public idRuoloProfessionale: number;
  public skype: string;
  public hangout: string;
}
