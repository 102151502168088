export class PageableList {
  public skip: number;
  public offset: number;
  public take: number;
  public count: number;
  public currentPage: number;
  public pages: number;
  public haveNextPage: boolean;
  public havePreviousPage: boolean;
  public idVaiA: any;
  public vaiAList: any;
}
