import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  deleteEvent = new Subject<boolean>();

  constructor(
    public router: Router,
    private ngxSmartModalService: NgxSmartModalService
  ) {}

  /**
   *
   * data un oggetto contenente informazioni relative ad una data
   * {string} format il formato in cui si vuole convertire l'oggetto data
   * @returns un oggetto di tipo Date
   */
  toDate(data: any, format: string): Date {
    const d = moment(data, format).toDate();
    d.setHours(12);
    return d;
  }

  showWarningAlert(text: string) {
    this.ngxSmartModalService.getModal('warningModal').removeData();
    this.ngxSmartModalService.setModalData(text, 'warningModal');
    this.ngxSmartModalService.getModal('warningModal').open();
  }

  showKoAlert() {
    this.ngxSmartModalService.getModal('koModal').open();
  }

  showOkAlert(text?: string) {
    this.ngxSmartModalService.getModal('okModal').removeData();
    if (!text) {
      this.ngxSmartModalService.setModalData(text, 'okModal');
    }
    this.ngxSmartModalService.getModal('okModal').open();
  }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  setUserSessionData(result: any, step: number, currentSocieta: any) {
    this.setToken(result.token);
    sessionStorage.setItem('username', result.username);
    sessionStorage.setItem('currentUser', JSON.stringify(result));
    sessionStorage.setItem('alertAnomalia', 'false');
    sessionStorage.setItem('alertCache', 'false');

    if (step === 2) {
      sessionStorage.setItem(
        'currentIdSocieta',
        JSON.stringify(currentSocieta)
      );
    } else {
      sessionStorage.setItem(
        'currentIdSocieta',
        JSON.stringify(result.dipendente.societa.id)
      );
    }
  }

  setCurrentMenu(menu: any) {
    sessionStorage.setItem('currentMenu', JSON.stringify(menu));
  }

  setToken(token: string) {
    sessionStorage.setItem('token', token);
  }

  /**
   * Metodo centralizzato per tornare indietro nell'intero applicativo
   */
  goBack() {
    const currFilter = this.getCurrFilter();
    if (!!currFilter) {
      this.router.navigate([currFilter.pathBack]);
    } else {
      window.history.back();
    }
  }

  addCurrFilter(searchModel: any, paginatore: any, pathBack: any) {
    const filter = {
      searchModel,
      paginatore,
      pathBack,
    };
    sessionStorage.setItem('curr-filter', JSON.stringify(filter));
  }

  removeCurrFilter() {
    sessionStorage.removeItem('curr-filter');
  }

  getCurrFilter() {
    return !!sessionStorage.getItem('curr-filter')
      ? JSON.parse(sessionStorage.getItem('curr-filter'))
      : null;
  }

  convertFilterDate(date: any): any {
    return date ? new Date(date) : date;
  }
}
