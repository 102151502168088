import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SidebarRightComponent } from './sidebar-right.component';


@NgModule({
    imports: [CommonModule],
    exports: [SidebarRightComponent],
    declarations: [SidebarRightComponent]
})
export class SidebarRightModule {}
