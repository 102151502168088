import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { PxltkFormType } from 'src/app/model/enum/pxltk-form-type';
import { PxltkSearchModel } from 'src/app/model/pxltk-search-model';
import { EncryptionService } from 'src/app/shared/shared-services/encryption.service';
import { SharedCallService } from 'src/app/shared/shared-services/shared-call.service';
import { PxltkFormSearchBaseComponent } from '../pxltk-form-search-base-component';

// componente che permette di cambiare i dettagli per fare una ricerca
@Component({
  selector: 'app-pxltk-search',
  templateUrl: './pxltk-search.component.html',
  styleUrls: ['./pxltk-search.component.scss'],
})
export class PxltkSearchComponent
  extends PxltkFormSearchBaseComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  panelOpenState: boolean;
  _searchModel: PxltkSearchModel[];

  get searchModel(): PxltkSearchModel[] {
    return this._searchModel;
  }

  /**
   * metodo che riceve da input una lista di pxlt-search-model che sono i tipi di campi che possono personalizzare la ricerca
   * In base al tipo di ogni campo, il file .html deciderà come disporli sulla pagina
   */
  @Input()
  set searchModel(searchModel: PxltkSearchModel[]) {
    // TODO FIXME GESTIRE MULTISELECT COMBO
    if (searchModel) {
      this._searchModel = searchModel;
      this._searchModel.forEach((field) => {
        if (!!field.defaultValue) {
          field[field.name] = field.defaultValue;
        }

        if (
          field.type === PxltkFormType.COMBO ||
          field.type === PxltkFormType.MULTICOMBO
        ) {
          // BUILD THIS COMBO
          this.buildCombo(field.comboName);
        }
      });
    }
  }

  @Input() showResetFilter = true;

  // OUTPUT
  @Output() searchEvent = new EventEmitter();
  @Output() resetOtherEvent = new EventEmitter();

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public sharedCallService: SharedCallService,
    public encryptionService: EncryptionService
  ) {
    super(sharedCallService, encryptionService);
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    // this.combo = [[]];
  }

  // metodo che manda in output una lista di pxlt-search-model. Ogni oggetto della lista personalizzerà la ricerca
  search() {
    this.searchEvent.emit(this.searchModel);
  }

  resetFiltri() {
    this.searchModel.forEach(
      (search) => (search[search.name] = search.nativeValue)
    );
  }

  resetOther(name: string) {
    this.resetOtherEvent.emit(name);
  }
}
