import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Select } from 'src/app/model/dto/select';
import { SharedCallService } from 'src/app/shared/shared-services/shared-call.service';
import { EncryptionService } from '../../shared/shared-services/encryption.service';

@Component({
  selector: 'app-pxltk-header',
  templateUrl: './pxltk-header.component.html',
  styleUrls: ['./pxltk-header.component.scss'],
})
export class PxltkHeaderComponent implements AfterViewInit, OnInit, OnDestroy {
  societaList: Select[];
  currentSocieta: Select;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public router: Router,
    private encryptionService: EncryptionService,
    private sharedCallService: SharedCallService
  ) {}

  loadSocieta() {
    this.sharedCallService.selectSocieta().subscribe(
      (result) => {
        result = this.encryptionService.decrypt(result);
        this.societaList = result.list;
        if (!!sessionStorage.getItem('currentIdSocieta')) {
          const idSocieta = JSON.parse(
            sessionStorage.getItem('currentIdSocieta')
          );
          this.societaList.forEach((select) => {
            if (select.value === idSocieta) {
              this.currentSocieta = select;
            }
          });
        }
      },
      (error) => {
        console.log('Errore selectSocieta');
      }
    );
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.loadSocieta();
  }

  ngOnInit(): void {}
}
