import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// componente che crea un box informativo sull'iva, che può essere a debito, a credito o da versare
@Component({
  selector: 'app-iva-box',
  templateUrl: './iva-box.component.html',
  styleUrls: ['./iva-box.component.scss'],
})
export class IvaBoxComponent {
  @Input() title: string;
  @Input() link: string;
  @Input() ivaVersare = 0;
  @Input() ivaDebito = 0;
  @Input() ivaCredito = 0;

  constructor(public router: Router) {}

  openLink() {
    this.router.navigate([this.link]);
  }
}
