import { Component, Input } from '@angular/core';
import { Color } from 'ng2-charts';
import { BarChart } from 'src/app/model/dto/bar-chart';

/*
  componente che crea un grafico a barre, le etichette vengono scritte in 'labels' e i dati in 'data'
  data è una lista del DTO BarChart, al suo interno contiene il dato e il label a cui appartiene
*/
@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent {
  @Input() labels: string[] = [];
  @Input() data: BarChart[] = [];
  @Input() title: string;

  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  public barChartColors: Color[] = [
    { backgroundColor: '#17a2b8' },
    { backgroundColor: '#ffc107' },
  ];
}
