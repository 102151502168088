import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/shared/shared-modules/material.module';
import { SharedComponentsModule } from '../../../shared/shared-modules/shared-component.module';
import { DashboardDipendenteRoutingModule } from './dashboard-dipendente-routing.module';
import { DashboardDipendenteComponent } from './dashboard-dipendente.component';
import { ConfettinatorModule } from '../../../components/confettinator/confettinator.module';

@NgModule({
  imports: [
    CommonModule,
    DashboardDipendenteRoutingModule,
    SharedComponentsModule,
    MaterialModule,
    ConfettinatorModule
  ],
  declarations: [DashboardDipendenteComponent],
})
export class DashboardDipendenteModule {}
