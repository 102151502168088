import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/shared-modules/material.module';
import { StarRatingComponent } from './star-rating.component';

@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule],
  declarations: [StarRatingComponent],
  exports: [StarRatingComponent],
})
export class StarRatingModule {}
