import { Component, Input } from '@angular/core';

// componente che crea un box informativo con la possibilità di impostarne un titolo, un icona e mostrare un eventuale valore numerico
@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {
  @Input() body: string;
  @Input() icon: string;
  @Input() type: string;
  @Input() title: string;
  @Input() isNumber = false;

  conversion(body: number): number {
    return Math.round((body + Number.EPSILON) * 100) / 100;
  }
}
