import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import * as Prism from 'prismjs';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants';
import { SharedCallService } from 'src/app/shared/shared-services/shared-call.service';
import { EncryptionService } from '../../shared/shared-services/encryption.service';
import { Societa } from '../dto/societa';
import { Utente } from '../dto/utente';
export abstract class AbstractBaseComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  currentUser: Utente;
  modalSubscribe: Subscription;
  idUtenteOperazione: number;
  nomeCompletoUtenteOperazione: string;
  currentIdSocieta: number;
  currentIdentificativoSocieta: string;
  backEndUrl: string;
  currentSocieta: Societa;

  optionsDateMonthYear = {
    format: 'MM/YYYY',
  };

  optionsDate = {
    format: 'DD/MM/YYYY',
  };

  optionsFullDate = {
    format: 'DD/MM/YYYY HH:mm',
  };

  constructor(
    public sharedCallService: SharedCallService,
    public encryptionService: EncryptionService
  ) {
    this.backEndUrl = Constants.URL_BACK_END;
  }

  ngOnDestroy(): void {
    if (this.modalSubscribe) {
      this.modalSubscribe.unsubscribe();
    }
  }

  ngAfterViewInit() {
    Prism.highlightAll();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.currentIdSocieta = JSON.parse(
      sessionStorage.getItem('currentIdSocieta')
    );

    this.sharedCallService
      .get(this.currentIdSocieta, Constants.GET_SOCIETA)
      .subscribe((result) => {
        result = this.encryptionService.decrypt(result);
        this.currentSocieta = result;
        this.currentIdentificativoSocieta =
          this.currentSocieta.identificativo.toUpperCase();
      });
  }
}
