import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfettinatorComponent } from './confettinator.component';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ConfettinatorComponent],
  exports: [ConfettinatorComponent],
})
export class ConfettinatorModule {}
