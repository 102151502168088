import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchScadenzarioCommand } from 'src/app/model/command/search-scadenzario-command';
import { IvaAnnuale } from 'src/app/model/dto/iva-annuale';
import { NumeroClientiFornitori } from 'src/app/model/dto/numero-clienti-fornitori';
import { NumeroFattureScad } from 'src/app/model/dto/numero-fatture-scad';
import { NumeroLavoratori } from 'src/app/model/dto/numero-lavoratori';
import { ResocontoAziendale } from 'src/app/model/dto/resoconto-aziendale';
import { AbstractBaseComponent } from 'src/app/model/parent/abstract-base-component';
import { Constants } from 'src/app/shared/constants';
import { SharedCallService } from 'src/app/shared/shared-services/shared-call.service';
import { EncryptionService } from '../../../shared/shared-services/encryption.service';

// componente che genera il template della dashboard amministratore

@Component({
  selector: 'app-dashboard-administrator',
  templateUrl: './dashboard-administrator.component.html',
  styleUrls: ['./dashboard-administrator.component.scss'],
})
export class DashboardAdministratorComponent
  extends AbstractBaseComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  doughnutChartData: any[];
  totImponibileFattEmesAnno: number;
  totaleEntrateAnno: number;
  titleSituazione: string;
  titleSituazioneIva: string;
  ivaAnnuale: IvaAnnuale;

  numeroLavoratori: NumeroLavoratori;
  numeroClientiFornitori: NumeroClientiFornitori;
  numeroFatScadute: NumeroFattureScad;
  numeroFatRicScadute: NumeroFattureScad;

  titleAdministrator: string;
  subtitleAdministrator: string;
  subtitle2: string;
  numeroScadenze: number;

  confetti= false;

  constructor(
    public sharedCallService: SharedCallService,
    public router: Router,
    public encryptionService: EncryptionService
  ) {
    super(sharedCallService, encryptionService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleSituazione = 'Situazione ' + new Date().getFullYear();
    this.titleAdministrator =
      'Ciao, ' +
      this.currentUser.dipendente.nome +
      ' ' +
      this.currentUser.dipendente.cognome +
      '!';
    this.subtitleAdministrator = 'Benvenuto nella Dashboard di Pixeltek!';
    this.subtitle2 = 'Ecco la situazione attuale!';
    this.loadFirstSection();
    this.loadDatiSituazioneAziendale();
    this.loadScadenze();

    if(!!this.currentUser.dipendente.dataNascita){
      this.confetti = this.isToday(this.currentUser.dipendente.dataNascita);
    }
  }

  isToday(dataS: Date){
    const data = new Date(dataS);
    const today = new Date()
    return data.getDate() == today.getDate() &&
    data.getMonth() == today.getMonth()
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  loadFirstSection() {
    this.sharedCallService
      .loadNumeroLavoratori(this.currentIdSocieta)
      .subscribe((result: NumeroLavoratori) => {
        result = this.encryptionService.decrypt(result);
        this.numeroLavoratori = result;
      });

    this.sharedCallService
      .loadNumeroClientiFornitori(this.currentIdSocieta)
      .subscribe((result: NumeroClientiFornitori) => {
        result = this.encryptionService.decrypt(result);
        this.numeroClientiFornitori = result;
      });

    this.sharedCallService
      .loadNumeroFattScadute(this.currentIdSocieta, false)
      .subscribe((result: NumeroFattureScad) => {
        result = this.encryptionService.decrypt(result);
        this.numeroFatScadute = result;
        // tslint:disable-next-line: max-line-length
        this.numeroFatScadute.importoFatInScadenza =
          Math.round(
            (this.numeroFatScadute.importoFatInScadenza + Number.EPSILON) * 100
          ) / 100;
        this.numeroFatScadute.importoFatScadute =
          Math.round(
            (this.numeroFatScadute.importoFatScadute + Number.EPSILON) * 100
          ) / 100;
      });

    this.sharedCallService
      .loadNumeroFattScadute(this.currentIdSocieta, true)
      .subscribe((result: NumeroFattureScad) => {
        result = this.encryptionService.decrypt(result);
        this.numeroFatRicScadute = result;
        // tslint:disable-next-line: max-line-length
        this.numeroFatRicScadute.importoFatInScadenza =
          Math.round(
            (this.numeroFatRicScadute.importoFatInScadenza + Number.EPSILON) *
              100
          ) / 100;
        // tslint:disable-next-line: max-line-length
        this.numeroFatRicScadute.importoFatScadute =
          Math.round(
            (this.numeroFatRicScadute.importoFatScadute + Number.EPSILON) * 100
          ) / 100;
      });
  }
  /**
   * metodo che visualizza un resoconto generale annuale
   */

  loadDatiSituazioneAziendale() {
    const year = new Date().getFullYear();
    this.sharedCallService
      .loadDatiSituazioneAziendale(this.currentIdSocieta, year)
      .subscribe(
        (result: ResocontoAziendale) => {
          result = this.encryptionService.decrypt(result);
          // PRIMO TRIMESTRE
          const totImponibileFattEmesPrimoTrim =
            Math.round(
              (result.fatturato.totImponibileFattEmesPrimoTrim +
                Number.EPSILON) *
                100
            ) / 100;

          // SECONDO TRIMESTRE
          const totImponibileFattEmesSecondoTrim =
            Math.round(
              (result.fatturato.totImponibileFattEmesSecondoTrim +
                Number.EPSILON) *
                100
            ) / 100;

          // TERZO TRIMESTRE
          const totImponibileFattEmesTerzoTrim =
            Math.round(
              (result.fatturato.totImponibileFattEmesTerzoTrim +
                Number.EPSILON) *
                100
            ) / 100;

          // QUARTO TRIMESTRE
          const totImponibileFattEmesQuartoTrim =
            Math.round(
              (result.fatturato.totImponibileFattEmesQuartoTrim +
                Number.EPSILON) *
                100
            ) / 100;

          this.totaleEntrateAnno =
            Math.round(
              (result.fatturato.totEntrateAnnuale + Number.EPSILON) * 100
            ) / 100;
          const totaleUsciteAnnuale =
            Math.round(
              (result.uscita.totUsciteAnnuale + Number.EPSILON) * 100
            ) / 100;
          const totaleMargineAnnuale =
            Math.round((result.margine.totMargAnnuale + Number.EPSILON) * 100) /
            100;
          this.doughnutChartData = [
            this.totaleEntrateAnno,
            totaleUsciteAnnuale,
            totaleMargineAnnuale,
          ];
          this.totImponibileFattEmesAnno =
            totImponibileFattEmesPrimoTrim +
            totImponibileFattEmesSecondoTrim +
            totImponibileFattEmesTerzoTrim +
            totImponibileFattEmesQuartoTrim;

          this.ivaAnnuale = result.ivaAnnuale;
        },
        (error) => {
          console.log('Errore loadDatiSituazioneAziendale');
        }
      );
  }

  loadScadenze() {
    const command = new SearchScadenzarioCommand();
    command.idSocieta = this.currentIdSocieta;
    command.idUtenteCreazione = null;
    command.idUtenteLettura = null;
    command.deleted = false;
    command.letto = false;
    command.take = 10;
    command.skip = 0;
    command.offset = 0;
    this.sharedCallService
      .search(command, Constants.SEARCH_SCADENZARIO)
      .subscribe((result) => {
        result = this.encryptionService.decrypt(result);
        this.numeroScadenze = result.count;
      });
  }
}
