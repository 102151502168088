import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tabella',
  templateUrl: './tabella.component.html',
  styleUrls: ['./tabella.component.scss'],
})
export class TabellaComponent implements OnInit {
  @Input() headers: any;
  @Input() lista: any;
  @Input() withActions: boolean;
  @Output() deleteEmitter = new EventEmitter();
  @Output() goToDetailEmitter = new EventEmitter();
  @Output() modifyEmitter = new EventEmitter();
  rows: [];
  constructor() {}

  ngOnInit() {}

  // goToPage(idVaiA: string, event: any) {
  //   this.selectionChangeEmitter.emit({idVaiA: idVaiA, event: event});
  // }

  // previousPage(skip: any, take: any) {
  //   this.previousPageEmitter.emit({skip: skip, take: take});
  // }

  // nextPage(skip: any, take: any, count: any) {
  //   this.nextPageEmitter.emit({skip: skip, take: take, count: count});
  // }
}
