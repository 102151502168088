import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule as MkAccordionModule } from 'angular-admin-lte';
import { MaterialModule } from 'src/app/shared/shared-modules/material.module';
import { PaginatoreComponent } from './paginatore.component';

@NgModule({
  imports: [CommonModule, MkAccordionModule, FormsModule, MaterialModule],
  declarations: [PaginatoreComponent],
  exports: [PaginatoreComponent],
})
export class PaginatoreModule {}
