import { SearchCommand } from './search-command';
export class ListCommand implements SearchCommand {
  userId: number;
  userRoleId: number;
  public skip: number;
  public take: number;
  public offset: number;
  public idSocieta: number;
  public orderByName: string;
  public orderByType: string;
  public deleted: boolean;
}
