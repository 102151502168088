import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddUpdateDipendenteCommand } from 'src/app/model/command/add-update-dipendente-command';
import { AddUpdateUtenteCommand } from 'src/app/model/command/add-update-utente-command';
import { Dipendente } from 'src/app/model/dto/dipendente';
import { TotaleStipendi } from 'src/app/model/dto/totale-stipendi';
import { PxltkDateFormat } from 'src/app/model/enum/pxltk-date-format';
import { AbstractBaseComponent } from 'src/app/model/parent/abstract-base-component';
import { SharedCallService } from 'src/app/shared/shared-services/shared-call.service';
import { Constants } from '../../../shared/constants';
import { EncryptionService } from '../../../shared/shared-services/encryption.service';
import { SharedService } from '../../../shared/shared-services/shared.service';
@Component({
  selector: 'app-dashboard-dipendente',
  templateUrl: './dashboard-dipendente.component.html',
  styleUrls: ['./dashboard-dipendente.component.scss'],
})
export class DashboardDipendenteComponent
  extends AbstractBaseComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  DATE = {
    format: 'DD/MM/YYYY',
    locale: 'it',
  };
  inModifica = false;
  inModificaPassword = false;
  dipendente: Dipendente;
  modificaForm: FormGroup;
  modificaPasswordForm: FormGroup;
  stipendi: TotaleStipendi;
  titoloIncassoAnno: string;
  modifica: any;
  confetti= false;
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public sharedCallService: SharedCallService,
    private sharedService: SharedService,
    public encryptionService: EncryptionService
  ) {
    super(sharedCallService, encryptionService);
  }

  get f() {
    return this.modificaForm.controls;
  }

  get pass() {
    return this.modificaPasswordForm.controls;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadStipendi();
    this.titoloIncassoAnno =
      'Totale stipendi netto ' + new Date().getFullYear();
    this.inModifica = false;
    this.inModificaPassword = false;
    this.dipendente = this.currentUser.dipendente;
    this.modificaForm = this.formBuilder.group({
      iban: [''],
      indirizzoResidenza: ['', Validators.required],
      indirizzoDomicilio: [''],
      cellulare: ['', Validators.required],
      codiceFiscale: ['', Validators.required],
      cartaIdentita: ['', Validators.required],
      luogoNascita: ['', Validators.required],
      dataNascita: ['', Validators.required],
    });
    this.modificaPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
    });
    if(!!this.dipendente.dataNascita){
      this.confetti = this.isToday(this.dipendente.dataNascita);
    }
  }

  isToday(dataS: Date){
    const data = new Date(dataS);
    const today = new Date()
    return data.getDate() == today.getDate() &&
    data.getMonth() == today.getMonth()
  }

  loadStipendi() {
    this.sharedCallService
      .loadStipendi(this.currentUser.dipendente.id)
      .subscribe((result: TotaleStipendi) => {
        result = this.encryptionService.decrypt(result);
        this.stipendi = result;
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  annullaModificaDati() {
    this.inModifica = false;
  }
  annullaModificaPassword() {
    this.inModificaPassword = false;
  }

  goToModificaDati() {
    this.inModifica = true;
    this.f.iban.setValue(this.dipendente.coordinateIban);
    this.f.indirizzoResidenza.setValue(this.dipendente.indirizzoResidenza);
    this.f.indirizzoDomicilio.setValue(this.dipendente.indirizzoDomicilio);
    this.f.cellulare.setValue(this.dipendente.cellulare);
    this.f.codiceFiscale.setValue(this.dipendente.codiceFiscale);
    this.f.cartaIdentita.setValue(this.dipendente.cartaIdentita);
    this.f.luogoNascita.setValue(this.dipendente.luogoNascita);
    this.f.dataNascita.setValue(
      !!this.dipendente.dataNascita
        ? new Date(this.dipendente.dataNascita).toLocaleString()
        : null
    );
  }

  goToModificaPassword() {
    this.inModificaPassword = true;
  }

  onSubmit() {
    this.spinner.show();
    if (this.modificaForm.invalid) {
      this.spinner.hide();
      const text = 'Form non valido!';
      this.sharedService.showWarningAlert(text);
    } else {
      const modifica = new AddUpdateDipendenteCommand();
      modifica.cartaIdentita = this.f.cartaIdentita.value;
      modifica.cellulare = this.f.cellulare.value;
      modifica.codiceFiscale = this.f.codiceFiscale.value;
      modifica.coordinateIban = this.f.iban.value;
      modifica.dataNascita = !!this.f.dataNascita.value
        ? this.sharedService.toDate(
            this.f.dataNascita.value,
            PxltkDateFormat.DATE
          )
        : null;
      modifica.indirizzoDomicilio = this.f.indirizzoDomicilio.value;
      modifica.indirizzoResidenza = this.f.indirizzoResidenza.value;
      modifica.luogoNascita = this.f.luogoNascita.value;
      modifica.cognome = this.dipendente.cognome;
      modifica.email = this.dipendente.email;
      modifica.id = this.dipendente.id;
      modifica.idSocieta = this.dipendente.societa.id;
      modifica.matricola = this.dipendente.matricola;
      modifica.nome = this.dipendente.nome;

      modifica.skype = this.dipendente.skype;
      modifica.hangout = this.dipendente.hangout;
      modifica.idRuoloProfessionale = this.dipendente.ruoloProfessionale.id;

      this.sharedCallService
        .createOrUpdate(modifica, Constants.MODIFICA_DIPENDENTE)
        .subscribe(
          (result) => {
            result = this.encryptionService.decrypt(result);
            this.spinner.hide();
            if (result) {
              const text =
                'Operazione effettuata con successo! Sarà necessario rieffettuare il login!';
              this.sharedService.showOkAlert(text);
              this.modalSubscribe = this.ngxSmartModalService
                .getModal('okModal')
                .onClose.subscribe((modal: NgxSmartModalComponent) => {
                  sessionStorage.clear();
                  localStorage.clear();
                  this.router.navigate(['/login']);
                });
            } else {
              this.sharedService.showKoAlert();
            }
          },
          (error) => {
            this.spinner.hide();
            this.sharedService.showKoAlert();
          }
        );
    }
  }

  onSubmitPassword() {
    this.spinner.show();
    if (this.modificaPasswordForm.invalid) {
      this.spinner.hide();
      const text = 'Form non valido!';
      this.sharedService.showWarningAlert(text);
    } else {
      const modifica = new AddUpdateUtenteCommand();
      modifica.id = this.currentUser.id;
      modifica.idRuolo = this.currentUser.ruolo.id;
      modifica.password = this.pass.password.value;
      modifica.username = this.currentUser.username;

      this.sharedCallService
        .createOrUpdate(modifica, Constants.MODIFICA_UTENTE)
        .subscribe(
          (result) => {
            result = this.encryptionService.decrypt(result);
            this.spinner.hide();
            if (result) {
              const text =
                'Operazione effettuata con successo! Sarà necessario rieffettuare il login!';
              this.sharedService.showOkAlert(text);
              this.modalSubscribe = this.ngxSmartModalService
                .getModal('okModal')
                .onClose.subscribe((modal: NgxSmartModalComponent) => {
                  sessionStorage.clear();
                  localStorage.clear();
                  this.router.navigate(['/login']);
                });
            } else {
              this.sharedService.showKoAlert();
            }
          },
          (error) => {
            this.spinner.hide();
            this.sharedService.showKoAlert();
          }
        );
    }
  }
}
