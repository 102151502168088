import ConfettiGenerator from "confetti-js";
import { Component } from '@angular/core';

@Component({
  selector: 'app-confettinator',
  templateUrl: './confettinator.component.html',
  styleUrls: ['./confettinator.component.scss'],
})
export class ConfettinatorComponent {

  ngOnInit() {
    var confettiSettings = { target: 'confettinator', rotate: true, start_from_edge: true, size: 0.8 };
    var confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
  }

}
