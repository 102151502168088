import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  AccordionModule as MkAccordionModule,
  BoxInfoModule as MkBoxInfoModule,
  BoxModule,
} from 'angular-admin-lte';
import { A2Edatetimepicker } from 'ng2-eonasdan-datetimepicker';
import { MaterialModule } from 'src/app/shared/shared-modules/material.module';
import { StarRatingModule } from '../star-rating/star-rating.module';
import { PxltkFormComponent } from './pxltk-form.component';

@NgModule({
  imports: [
    CommonModule,
    MkAccordionModule,
    StarRatingModule,
    BoxModule,
    MkBoxInfoModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    A2Edatetimepicker,
    TranslateModule,
  ],
  declarations: [PxltkFormComponent],
  exports: [PxltkFormComponent],
})
export class PxltkFormModule {}
